import {Box} from '@mui/material/'
import type {FC} from 'react'
import snapEdgeImage from '../images/on-logic-mbai-two.png'
import {useCustomTranslation} from '../../../../../../shared/hooks/useCustomTranslation'
import ConnectionOptionCard from './ConnectionOptionCard'

const ConnectionOptionCardTable: FC = () => {
	const [t] = useCustomTranslation('edge_api')
	const options = [
		{
			image: snapEdgeImage,
			text: 'SnapEdge',
			alt: 'SnapEdge',
			value: 'snap_edge',
		},
		{
			text: t('your_own_mqtt_publisher'),
			value: 'own_mqtt_publisher',
		},
		{
			text: t('deploy_edge_in_your_own_infrastructure'),
			value: 'montblanc_edge_container_own_infraestructure',
		},
	]

	return (
		<Box
			sx={
				{
					display: 'flex',
					gap: 2,
					justifyContent: 'center',
					alignItems: 'center',
					// flexWrap: 'wrap',
					mb: 2,
				}
			}
		>
			{
				options.map((option) => (
					<ConnectionOptionCard
						key={option.value}
						value={option.value}
						image={option.image}
						text={option.text}
						alt={option.alt}
					/>
				))
			}

		</Box>
	)
}

export default ConnectionOptionCardTable
