import type {
	ReactNode, FC, ReactElement,
} from 'react'
import {
	createContext, useContext, useState, useMemo, useCallback, useEffect, useRef,
} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import type {GridRowSelectionModel} from '@mui/x-data-grid'
import {useSelector} from 'react-redux'
import type {
	OverlayedViewSignalInterface, ViewsSignalInterface, SignalInterface,
} from '../interfaces/signal.ts'
import {useGetViewsByLineQuery} from '../../apis/base.ts'
import type {HorizontalLineInterface, ViewInterface} from '../interfaces/view.ts'
import type {RootState} from '../../containers/App/store.ts'

interface ViewsProviderProps {
    children?: ReactNode,
}

export interface ViewsContextProps {
	allViews: ViewInterface[],
	filteredViews: ViewInterface[],
	setFilteredViews: (views: ViewInterface[]) => void,
	activeViewSignals: ViewsSignalInterface[] | OverlayedViewSignalInterface[],
	refetchViews: () => void,
	loadingViews: boolean,
	viewsError: boolean,
	fetchingViews: boolean,
	activeFilter: boolean,
	setActiveFilter: (active: boolean) => void,
	setAllViews: (views: ViewInterface[]) => void,
	updateViewSignals: (signalsIds: GridRowSelectionModel) => void,
	setSignalHeight: (signal: {id: string, height: number}) => void,
	reorderActiveViewSignal: (signalOrder: string[]) => void,
	updateSignalHorizontalLines: (signal: {id: string, lines: HorizontalLineInterface[]}) => void,
	activeView: ViewInterface | null,
	setActiveView: (view: ViewInterface | null) => void,
}

const ViewsContext = createContext<ViewsContextProps | null>(null)

const useViewsContext = (): ViewsContextProps | null => useContext(ViewsContext)

const ViewsProvider: FC<ViewsProviderProps> = ({children}): ReactElement => {
	// Hooks
	const {id: lineId} = useParams()
	const [searchParams, setSearchParams] = useSearchParams()

	// Basic Variables
	const currentViewId = searchParams.get('view')
	const currentViewIdRef = useRef<string | null>(currentViewId)

	// Redux
	const activeSignals = useSelector((state: RootState) => state.signals.active)

	// States
	const [currentViewSignals, setCurrentViewSignals] = useState<ViewsSignalInterface[] | OverlayedViewSignalInterface[]>([])
	const [filteredViews, setFilteredViews] = useState<ViewInterface[]>([])
	const [activeFilter, setActiveFilter] = useState<boolean>(false)
	const [allViews, setAllViews] = useState<ViewInterface[]>([])
	const [activeView, setActiveView] = useState<ViewInterface | null>(null)

	// RTK Queries
	const {
		data: views,
		isFetching,
		isLoading,
		isError: viewsError,
		refetch,
	} = useGetViewsByLineQuery({lineId: lineId as string}, {skip: !lineId})

	// Functions
	const setViewSignals = useCallback((): void => {
		if (activeView) {
			setCurrentViewSignals(activeView.selected_signals as ViewsSignalInterface[] | OverlayedViewSignalInterface[])
		} else {
			setCurrentViewSignals([])
		}
	}, [activeView, setCurrentViewSignals])

	// This is a sync function that updates the view signals
	// Whenever activeSignals change or the currentViewSignals change
	// This function will update the currentViewSignals
	const updateViewSignals = useCallback((signalsIds: GridRowSelectionModel): void => {
	// Find signals that are in signalsIds but not yet in currentViewSignals
		const newSignals = (signalsIds as string[]).filter(
			(signalId: string) => !currentViewSignals.some((viewSignal) => viewSignal.signal === signalId),
		)

		// If there are new signals, add them to currentViewSignals with a default graph_height of 275
		if (newSignals.length > 0) {
			const updatedViewSignals = [
				...currentViewSignals,
				...newSignals.map((signalId: string) => ({
					signal: signalId,
					graph_height: 275, // Default height for new signals
				})),
			]

			// Update the state with the new signals
			setCurrentViewSignals(updatedViewSignals)
		}
	// If no new signals, no need to update the state
	}, [currentViewSignals])

	const setSignalHeight = useCallback((signal: {id: string, height: number}): void => {
		setCurrentViewSignals((prev) => prev.map((item) => {
			if (item.signal === signal.id) {
				return {
					...item,
					graph_height: signal.height,
				}
			}
			return item
		}))
	}, [])

	const updateSignalHorizontalLines = useCallback((signal: { id: string, lines: HorizontalLineInterface[] }): void => {
		setCurrentViewSignals((prev) => prev.map((item) => {
			if (item.signal === signal.id) {
				return {
					...item,
					horizontal_lines: signal.lines,
				}
			}
			return item
		}))
	}, [])

	// Effects
	// This effect only runs when the currentViewId (changing views) changes essentially
	useEffect(() => {
		setViewSignals()
	}, [setViewSignals])

	// If there are no active filters, reset the filtered views
	useEffect(() => {
		if (!activeFilter) {
			setFilteredViews(allViews as ViewInterface[])
		}
	}, [allViews, setFilteredViews, activeFilter])

	useEffect(() => {
		const {current} = currentViewIdRef
		if (views) {
			if (current) {
				const view = views.find((view) => view.id === current)
				if (view) {
					setActiveView(view)
				}
			}
			setAllViews(views)
			currentViewIdRef.current = null
		}
	}, [views])

	useEffect(() => {
		const newSearchParams = new URLSearchParams(window.location.search)
		if (activeView) {
			newSearchParams.set('view', activeView.id as string)
		} else {
			newSearchParams.delete('view')
		}
		setSearchParams(newSearchParams, {replace: true})
	}, [activeView, setSearchParams])

	useEffect(() => {
	// Check if the currentViewSignals are in sync with activeSignals
		const areArraysEqual = (): boolean => {
		// Extract the signal IDs from currentViewSignals
			const currentSignalIds = currentViewSignals.map((signal) => signal.signal)
			// Check if both arrays contain the same signals (order doesn't matter)
			return (
				activeSignals.length === currentSignalIds.length
			&& activeSignals.every((signal: SignalInterface) => currentSignalIds.includes(signal.id))
			)
		}

		// If arrays are already equal, skip the update
		if (areArraysEqual()) {
			return
		}

		// Filter out signals that are in activeSignals but not in currentViewSignals (to add new ones)
		const newSignals = activeSignals.filter(
			(signal: SignalInterface) => !currentViewSignals.some((viewSignal) => viewSignal.signal === signal.id),
		)

		// Filter out signals that are in currentViewSignals but not in activeSignals (to remove them)
		const signalsToRemove = currentViewSignals.filter(
			(viewSignal) => !activeSignals.some((signal: SignalInterface) => signal.id === viewSignal.signal),
		)

		// Add new signals with default graph_height of 275
		let updatedViewSignals = [
			...currentViewSignals,
			...newSignals.map((signal: SignalInterface) => ({signal: signal.id})),
		]

		// Remove signals that are no longer in activeSignals
		if (signalsToRemove.length > 0) {
			updatedViewSignals = updatedViewSignals.filter(
				(viewSignal) => !signalsToRemove.some((removeSignal) => removeSignal.signal === viewSignal.signal),
			)
		}

		// If no active signals, clear the view signals
		if (activeSignals.length === 0) {
			updatedViewSignals = []
		}

		// Update the state with the synchronized signals
		setCurrentViewSignals(updatedViewSignals)
	}, [activeSignals, currentViewSignals, activeView])

	// Whenever lineId changes, always reset the currentView
	useEffect(() => {
		setActiveView(null)
	}, [lineId])

	const reorderActiveViewSignal = useCallback((signalOrder: string[]): void => {
		const newActiveSignalsViews = signalOrder.map((signalId: string) => currentViewSignals.find((viewSignal) => viewSignal.signal === signalId)).filter(Boolean) as ViewsSignalInterface[] // Ensure that we don't end up with any undefined values
		setCurrentViewSignals(newActiveSignalsViews)
	}, [currentViewSignals])

	return (
		<ViewsContext.Provider
			value={
				useMemo(() => ({
					allViews,
					refetchViews: refetch,
					loadingViews: isLoading,
					viewsError,
					fetchingViews: isFetching,
					activeViewSignals: currentViewSignals,
					filteredViews,
					setFilteredViews,
					activeFilter,
					setActiveFilter,
					setAllViews,
					updateViewSignals,
					setSignalHeight,
					reorderActiveViewSignal,
					updateSignalHorizontalLines,
					activeView,
					setActiveView,
				}), [
					refetch,
					isLoading,
					viewsError,
					allViews,
					currentViewSignals,
					isFetching,
					filteredViews,
					setFilteredViews,
					activeFilter,
					setActiveFilter,
					setAllViews,
					updateViewSignals,
					setSignalHeight,
					reorderActiveViewSignal,
					updateSignalHorizontalLines,
					activeView,
					setActiveView,
				])
			}
		>
			{children}
		</ViewsContext.Provider>
	)
}

export {
	useViewsContext, ViewsProvider, ViewsContext,
}
