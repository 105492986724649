import {baseApi} from './base'
import type {EventInterface} from '../shared/interfaces/usage/event.ts'

const usageAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		usageEvent: builder.mutation<{ message: string }, EventInterface >({
			query: (event) => ({
				url: '/usage/event/',
				method: 'POST',
				body: event,
			}),
		}),
	}),
	overrideExisting: false,
})

export const {useUsageEventMutation} = usageAPI
