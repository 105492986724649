type StepsHookInterface = {
	label: string,
	description: string,
}

interface StepsEdgeApiHookInterface {
	stepFirst: StepsHookInterface[],
	steps201: StepsHookInterface[],
	stepsIfConnectionTrue: StepsHookInterface[],
	stepsMqtt: StepsHookInterface[],

}

export const useSteps = (): StepsEdgeApiHookInterface => {
	const stepFirst = [
		{label: 'stepper.step_one.label', description: ''},
	]

	const steps201 = [
		{label: 'stepper.step_one.label', description: ''},
		{label: 'stepper.step_two.label', description: ''},
		{label: 'stepper.step_three.label', description: ''},
		{label: 'stepper.step_four.label', description: ''},
		{label: 'stepper.step_five.label', description: ''},
		{
			label: 'stepper.step_six.label',
			description: 'stepper.step_six.description',
		},
	]

	const stepsMqtt = [
		{label: 'stepper.step_one.label', description: ''},
		{label: 'stepper.step_three.label', description: ''},
		{label: 'stepper.step_mqtt_data.label', description: ''},
	]
	const stepsIfConnectionTrue = [
		{
			label: 'stepper.step_connect_true.label',
			description: 'stepper.step_connect_true.description_desktop',
		},
	]

	return {
		stepFirst, steps201, stepsIfConnectionTrue, stepsMqtt,
	}
}
