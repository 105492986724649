import type {ReactNode, FC} from 'react'
import {StrictMode} from 'react'
import {Box} from '@mui/material'

type Props = {
    children: ReactNode,
}

const BasicDashboardContainer: FC<Props> = (
	{children},
) => (
	<StrictMode>
		<Box
			data-testid="mbai-basic-dashboard-container-box"
		>
			<Box
				sx={
					{
						px: {
							sm: 3,
							xs: 2,
						},
						pb: {
							sm: 3,
							xs: 2,
						},
						pt: {
							sm: 2,
							xs: 1,
						},
					}
				}
				flexGrow={1}
			>
				<Box>
					{children}
				</Box>
			</Box>
		</Box>
	</StrictMode>
)

export default BasicDashboardContainer
