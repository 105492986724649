import type {
	BaseSyntheticEvent, FC, ReactElement,
} from 'react'
import {StrictMode, useState} from 'react'
import {
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popover,
	Tooltip,
} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import {NavLink} from 'react-router-dom'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'
import type {UserInterface} from '../../../shared/interfaces/user'
import type {UserContextProps} from '../../App/UserProvider'
import {useUserContext} from '../../App/UserProvider'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation'
import {getUserFullName} from '../../../util-functions'
import type {AuthContextProps} from '../../App/AuthProvider'
import {useAuthContext} from '../../App/AuthProvider'

const AppbarProfile: FC = (): ReactElement => {
	const {logoutUser} = useAuthContext() as AuthContextProps
	const {currentUser} = useUserContext() as UserContextProps
	const [t] = useCustomTranslation('common')
	const [t2] = useCustomTranslation('settings')
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = (event: BaseSyntheticEvent): void => {
		setAnchorEl(event.currentTarget)
	}
	const fullName = getUserFullName(currentUser as UserInterface)

	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	return (
		<StrictMode>
			<Tooltip
				title={t('profile.title')}
				arrow
			>
				<IconButton
					id="mbai-appbar-profile-button"
					data-testid="mbai-appbar-profile-button"
					data-cy="mbai-appbar-profile-button"
					aria-describedby={id}
					onClick={handleClick}
					sx={
						{ml: 1}
					}
				>
					<PersonOutlineTwoToneIcon />
				</IconButton>
			</Tooltip>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={
					{
						vertical: 'bottom',
						horizontal: 'left',
					}
				}
				sx={
					{mt: 2.5}
				}
			>
				<Paper sx={
					{width: 320, maxWidth: '100%'}
				}
				>
					<MenuList>
						<NavLink to="/settings">
							<MenuItem>
								<ListItemText
									sx={
										{color: 'text.secondary'}
									}
									primary={fullName}
									secondary={currentUser ? currentUser.email : null}
								/>
							</MenuItem>
						</NavLink>
						<Divider
							component="li"
							sx={
								{my: 1}
							}
						/>
						<NavLink to="/settings">
							<MenuItem id="user-profile-link">
								<ListItemIcon>
									<SettingsIcon />
								</ListItemIcon>
								<ListItemText
									sx={
										{color: 'text.secondary'}
									}
								>
									{t2('title')}
								</ListItemText>
							</MenuItem>
						</NavLink>
						<Divider
							component="li"
							sx={
								{my: 1}
							}
						/>
						<MenuItem
							id="user-logout-button"
							onClick={logoutUser}
						>
							<ListItemIcon>
								<LogoutIcon />
							</ListItemIcon>
							<ListItemText
								sx={
									{color: 'text.secondary'}
								}
							>
								{t('profile.logout')}
							</ListItemText>
						</MenuItem>
					</MenuList>
				</Paper>
			</Popover>
		</StrictMode>
	)
}

export default AppbarProfile
