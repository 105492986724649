import type {FC, ReactElement} from 'react'
import {
	StrictMode, useEffect, useState, useRef,
} from 'react'
import {
	Box, CardActions, CardContent, CardHeader, Grid, Skeleton, Typography,
} from '@mui/material'
import {NavLink} from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import BarChartIcon from '@mui/icons-material/BarChart'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import {useInView} from 'react-intersection-observer'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'
import BasicCard from '../../../../shared/components/BasicCard.tsx'
import ActionButton from '../components/ActionButton.tsx'
import type {EquipmentInterface} from '../../../../shared/interfaces/equipment.ts'
import {useEquipmentTypes} from '../../../../shared/hooks/useEquipmentTypes.ts'
import {useGetAvailabilityPerDayQuery} from '../../../../apis/lineDashboard.ts'
import D3DailyProductionBar from './D3DailyProductionBar.tsx'
import {useGetCockpitDisconnectedSignalsQuery, useGetCockpitWarningsSignalBasedQuery} from '../../../../apis/cockpit.ts'
import CardWarning from '../shared/CardWarning.tsx'
import {filterWarnings} from '../../../../util-functions.ts'
import LineStatusChip from '../../../../shared/components/LineStatusChip.tsx'

interface EquipmentCardProps {
    equipment: EquipmentInterface,
    showDetails?: boolean,
}

const EquipmentCard: FC<EquipmentCardProps> = ({equipment, showDetails = true}): ReactElement => {
	// Refs
	const equipmentCardGraphRef = useRef<HTMLDivElement | null>(null)
	const {ref, inView} = useInView({triggerOnce: true})
	const [startDate, setStartDate] = useState<string>('')
	const [chartInnerWidth, setChartInnerWidth] = useState<number | null>(null)
	const [endDate] = useState<string>(new Date().toISOString())
	const summarizeBy = 'day'
	const margin = {
		top: 30, right: 30, bottom: 40, left: 40,
	}
	const {data: dailyAvailabilities, isLoading} = useGetAvailabilityPerDayQuery({
		equipmentId: equipment.id,
		startDate,
		endDate,
		summarize_by: summarizeBy,
		timeZoneOffset: new Date().getTimezoneOffset(),
	}, {skip: !inView})

	const {data: cockpitWarnings} = useGetCockpitWarningsSignalBasedQuery(
		{equipmentId: equipment.id as string},
	)
	const {data: disconnectedSignals} = useGetCockpitDisconnectedSignalsQuery({equipmentId: equipment.id})
	const [t] = useCustomTranslation('common')
	const [tLineManagement] = useCustomTranslation('line_management')
	const [tLinesOverview] = useCustomTranslation('lines_overview')
	const [tCockpit] = useCustomTranslation('cockpit')

	const {getEquipmentType} = useEquipmentTypes()

	useEffect(() => {
		const start = new Date()
		start.setDate(start.getDate() - 7)
		setStartDate(start.toISOString())
	}, [])

	const filteredWarnings = filterWarnings(cockpitWarnings || [])

	useEffect(() => {
		const {current} = equipmentCardGraphRef
		if (!current) return undefined
		const oeeReportContainerObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setChartInnerWidth(entry.contentRect.width - (margin.left + margin.right))
			})
		})
		oeeReportContainerObserver.observe(current)
		return () => oeeReportContainerObserver.disconnect()
	}, [margin.left, margin.right])

	return (
		<StrictMode>
			<BasicCard
				key={equipment.id}
				id={equipment.id}
				data-name="equipment-card"
				data-testid="mbai-equipments-overview-equipment-card-component"
				sx={
					{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
					}
				}
			>
				<CardHeader
					sx={
						{pb: 0, borderBottom: 'none !important'}
					}
					title={
						(
							<NavLink
								to={`/equipment/${equipment.id}`}
								style={
									{textDecoration: 'none'}
								}
							>
								<Typography
									variant="h3"
									data-name="equipment-title"
									data-cy="mbai-equipments-overview-equipment-card-title"
									sx={
										{
											color: 'text.primary',
											mb: 0,
										}
									}
								>
									{equipment.name}
								</Typography>
							</NavLink>
						)
					}
					action={
						(
							<Box
								sx={
									{display: 'flex'}
								}
							>
								{
									disconnectedSignals && disconnectedSignals.length ? (
										<NavLink to="/all-signals?connection_status=Disconnected">
											<LineStatusChip
												connectivityStatus="Disconnected"
												bold
												signal
												tooltipText={tCockpit('disconnectedSignalsTooltip', {count: disconnectedSignals.length})}
												displayLabel={false}
											/>
										</NavLink>
									) : null
								}
								{
									filteredWarnings && filteredWarnings.length > 0 ? (
										<CardWarning
											warnings={filteredWarnings}
											equipmentId={equipment.id}
										/>
									) : null
								}
							</Box>
						)
					}
				/>
				<CardContent
					sx={
						{pb: '0 !important'}
					}
					ref={ref}
				>
					<Box
						sx={
							{
								height: '200px',
								backgroundColor: 'background.fadedOpaque',
							}
						}
						data-cy="mbai-line-card-image"
						ref={equipmentCardGraphRef}
					>
						{
							isLoading ? (
								<Box
									sx={
										{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '100%',
										}
									}
								>
									<Skeleton
										variant="circular"
										style={
											{
												height: '150px',
												width: '150px',
											}
										}
									/>
								</Box>
							) : dailyAvailabilities && dailyAvailabilities && dailyAvailabilities.length > 0 ? (
								<D3DailyProductionBar
									data={dailyAvailabilities}
									width={chartInnerWidth as number}
									height={135}
									summarizeBy={summarizeBy}
									tooltip={false}
									margin={margin}
								/>

							) : (
								<Box
									sx={
										{
											px: 2,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '100%',
											textAlign: 'center',
										}
									}
								>
									<Typography
										variant="body2"
										color="text.secondary"
									>
										{t('availability_pie.no_data_available')}
									</Typography>
								</Box>
							)
						}
					</Box>
					{
						showDetails ? (
							<Box
								sx={
									{
										pt: 1.5,
										pb: 0.5,
									}
								}
							>
								<Box
									sx={
										{mb: '-3px'}
									}
								>
									<Typography
										variant="caption"
										color="text.disabled"
										component="span"
									>
										{`${tLineManagement('equipment_management.type')}:`}
									</Typography>
									<Typography
										variant="caption"
										color="text.primary"
										component="span"
										sx={
											{ml: 0.5}
										}
									>
										{getEquipmentType(equipment.machine_sub_system_type as string)}
									</Typography>
								</Box>
								<Box
									sx={
										{mb: '-3px'}
									}
								>
									<Typography
										variant="caption"
										color="text.disabled"
										component="span"
									>
										{`${t('programs.n_programs')}:`}
									</Typography>
									<Typography
										variant="caption"
										color="text.primary"
										component="span"
										sx={
											{ml: 0.5}
										}
									>
										{equipment.n_programs}
									</Typography>
								</Box>
								<Box
									sx={
										{mb: '-3px'}
									}
								>
									<Typography
										variant="caption"
										color="text.disabled"
										component="span"
									>
										{`${t('programs.n_signals')}:`}
									</Typography>
									<Typography
										variant="caption"
										color="text.primary"
										component="span"
										sx={
											{ml: 0.5}
										}
									>
										{equipment.n_signals}
									</Typography>
								</Box>
							</Box>
						) : null
					}
				</CardContent>
				<CardActions
					sx={
						{
							pt: '0',
							borderTop: 'none !important',
						}
					}
				>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={4}
						>
							<ActionButton
								title={tLineManagement('equipment_dashboard.view')}
								dataName="equipment-view-equipment-button"
								dataCy="mbai-equipments-overview-equipment-card-view-equipment-button"
								link={`/equipment/${equipment.id}`}
								icon={<BarChartIcon />}
							/>
						</Grid>
						<Grid
							item
							xs={4}
						>
							<ActionButton
								title={tLinesOverview('view_signals')}
								dataName="equipment-view-signals-button"
								dataCy="mbai-equipments-overview-equipment-card-view-signals-button"
								link={`/line/${equipment.machine}/signals?equipment=${equipment.id}`}
								icon={<TrendingUpIcon />}
							/>
						</Grid>
						<Grid
							item
							xs={4}
						>
							<ActionButton
								title={tLineManagement('equipment_management.configure')}
								dataCy="mbai-equipments-overview-equipment-card-view-equipment-management-button"
								link={`/equipment/${equipment.id}/equipment_management/`}
								icon={<EditIcon />}
							/>
						</Grid>
					</Grid>
				</CardActions>
			</BasicCard>
		</StrictMode>
	)
}

export default EquipmentCard
