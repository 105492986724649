import type {FeatureCollection} from 'geojson'
import {baseApi} from './base'
import type {BaseFetchResultsInterface} from '../shared/interfaces/base.ts'
import type {CockpitWarningInterface} from '../shared/interfaces/cockpitWarning.ts'
import type {DisconnectedSignalsResponse} from '../shared/interfaces/disconnectedSignals.ts'

const cockpitAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getCockpitDisconnectedSignals: builder.query<DisconnectedSignalsResponse[], {
			lineId?: string,
			equipmentId?: string,
		}>({
			query: ({lineId, equipmentId}) => ({
				url: 'disconnected-signals/',
				params: {machine_id: lineId, machine_sub_system_id: equipmentId},
			}),
			transformResponse: (response: BaseFetchResultsInterface<DisconnectedSignalsResponse>) => response.results,
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
		getCockpitList: builder.query<FeatureCollection, void>({
			query: () => 'cockpit/',
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
		getCockpitWarningsSignalBased: builder.query<CockpitWarningInterface[], {
			equipmentId?: string,
			lineId?: string,
		}>({
			query: ({equipmentId, lineId}) => ({
				url: 'cockpitwarningssignalbased/',
				params: equipmentId ? {signal__machine_sub_system: equipmentId} : {signal__machine_sub_system__machine: lineId},
			}),
			transformResponse: (response: BaseFetchResultsInterface<CockpitWarningInterface>) => response.results,
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
	}),
	overrideExisting: false,
})

export const {
	useGetCockpitDisconnectedSignalsQuery, useGetCockpitListQuery, useGetCockpitWarningsSignalBasedQuery,
} = cockpitAPI
