import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

interface TutorialSliceInterface {
    isIntroOpen: boolean,
    isMainOpen: boolean,
    isExitConfirmOpen: boolean,
    isActive: boolean,
}

interface UpdateTutorialPayloadInterface {
    tutorial_skipped?: boolean,
    last_step_completed?: number,
    exit_confirm_open?: boolean,
}

const initialState: TutorialSliceInterface = {
	isIntroOpen: false,
	isMainOpen: false,
	isExitConfirmOpen: false,
	isActive: false,
}

export const tutorial = createSlice({
	name: 'tutorial',
	initialState,
	reducers: {
		setIsIntroTutorialOpen: (state, action: PayloadAction<boolean>) => ({
			...state,
			isIntroOpen: action.payload,
		}),
		setIsMainTutorialOpen: (state, action: PayloadAction<boolean>) => ({
			...state,
			isMainOpen: action.payload,
		}),
		setIsTutorialActive: (state, action: PayloadAction<boolean>) => ({
			...state,
			isActive: action.payload,
		}),
		setIsExitConfirmOpen: (state, action: PayloadAction<boolean>) => {
			if (action.payload) {
				return {
					...state,
					isExitConfirmOpen: true,
					isMainOpen: false,
				}
			}
			return {
				...state,
				isExitConfirmOpen: false,
				isMainOpen: true,
			}
		},
		updateTutorial: (state, action: PayloadAction<UpdateTutorialPayloadInterface>) => {
			// If tutorial was skipped, hide everything
			if (action.payload.tutorial_skipped) {
				return {
					...state,
					isIntroOpen: false,
					isMainOpen: false,
					isExitConfirmOpen: false,
				}
			}
			// Only show intro tutorial if last step completed is less than 0
			if (action.payload.last_step_completed && action.payload.last_step_completed < -1) {
				return {
					...state,
					isIntroOpen: true,
					isMainOpen: false,
					isExitConfirmOpen: false,
				}
			}
			// If exit confirm open is true, only open ExitConfirmModal
			if (action.payload.exit_confirm_open) {
				return {
					...state,
					isIntroOpen: false,
					isMainOpen: false,
					isExitConfirmOpen: true,
				}
			}
			return {
				...state,
				isIntroOpen: false,
				isMainOpen: true,
				isExitConfirmOpen: false,
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	updateTutorial, setIsIntroTutorialOpen, setIsExitConfirmOpen, setIsMainTutorialOpen,
} = tutorial.actions
export default tutorial.reducer
