import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {SliceTooltipTracesInterface} from '../../shared/interfaces/trace'

interface SliceTooltipProps {
    open: boolean,
    xValue: number | Date,
    program: string,
	traces: SliceTooltipTracesInterface[],
	staticTooltipOpen: boolean,
	xPosition: number,
	xDomain: [number | Date, number | Date] | null,
	cursorPosition: number,
	signalId: string,
}

const initialState: SliceTooltipProps = {
	open: false,
	xValue: 0,
	program: '',
	traces: [],
	staticTooltipOpen: false,
	xPosition: 0,
	xDomain: null,
	cursorPosition: 0,
	signalId: '',
}

export const sliceTooltip = createSlice({
	name: 'sliceTooltip',
	initialState,
	reducers: {
		setOpen: (state, action: PayloadAction<boolean>) => ({
			...state,
			open: action.payload,
		}),
		setStaticTooltipOpen: (state, action: PayloadAction<boolean>) => ({
			...state,
			staticTooltipOpen: action.payload,
		}),
		setXValue: (state, action: PayloadAction<number | Date>) => ({
			...state,
			xValue: action.payload,
		}),
		setXPosition: (state, action: PayloadAction<number>) => ({
			...state,
			xPosition: action.payload,
		}),
		setCursorPosition: (state, action: PayloadAction<number>) => ({
			...state,
			cursorPosition: action.payload,
		}),
		setGlobalXDomain: (state, action: PayloadAction<[number | Date, number | Date] | null>) => ({
			...state,
			xDomain: action.payload,
		}),
		setTraces: (state, action: PayloadAction<SliceTooltipTracesInterface[]>) => ({
			...state,
			traces: action.payload,
		}),
		setSignalId: (state, action: PayloadAction<string>) => ({
			...state,
			signalId: action.payload,
		}),
	},
})

// Action creators are generated for each case reducer function
export const {
	setOpen,
	setXValue,
	setTraces,
	setStaticTooltipOpen,
	setXPosition,
	setGlobalXDomain,
	setCursorPosition,
	setSignalId,
} = sliceTooltip.actions
export default sliceTooltip.reducer
