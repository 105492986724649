import type {GridRowModel} from '@mui/x-data-grid'
import type {FC, ReactElement} from 'react'
import {NavLink} from 'react-router-dom'
import {IconButton, Tooltip} from '@mui/material'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'

interface Props{
    row: GridRowModel,
}

const GoToAnomaly: FC<Props> = ({row}): ReactElement => {
	const [t] = useCustomTranslation('anomalies')

	return (
		<Tooltip title={t('go_to_anomaly')}>
			<NavLink
				to={
					{
						pathname: `/line/${row.machine.id}/signals`,
						search: `?tab=anomalies&batch=${row.batch.id}&anomaly=${row.id}&equipment=${row.machine_sub_system.id}`,
					}
				}
				data-cy="mbai-go-to-anomaly"
			>
				<IconButton
					data-testid="mbai-go-to-anomaly-button"
				>
					<TroubleshootIcon />
				</IconButton>
			</NavLink>
		</Tooltip>
	)
}

export default GoToAnomaly
