import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

interface Step {
    description: string,
    label: string,
}

const initialState = {
	activeStep: 0,
	steps: [
		{label: 'stepper.step_one.label', description: ''},
	],
	validate: false,
	onlineThreeMinutes: undefined as boolean | undefined,
}

const navigationSlice = createSlice({
	name: 'navigation',
	initialState,
	reducers: {
		setActiveStep: (state, action: PayloadAction<number>) => {
			state.activeStep = action.payload
		},
		setSteps: (state, action: PayloadAction<{label: string, description: string}[]>) => {
			state.steps = action.payload
		},
		addSteps: (state, action: PayloadAction<Step[]>) => {
			state.steps = [...state.steps, ...action.payload]
		},
		setValidate: (state, action: PayloadAction<boolean>) => {
			state.validate = action.payload
		},
		setOnlineThreeMinutes: (state, action: PayloadAction<boolean | undefined>) => {
			state.onlineThreeMinutes = action.payload
		},
		decrementActiveStep: (state) => {
			state.activeStep -= 1
		},
		incrementActiveStep: (state) => {
			state.activeStep += 1
		},
		resetNavigation: () => initialState,
	},
})

export const {
	setActiveStep, setSteps, setValidate, setOnlineThreeMinutes, decrementActiveStep, incrementActiveStep, addSteps, resetNavigation,
} = navigationSlice.actions

export default navigationSlice.reducer
