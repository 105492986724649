import type {FC, ReactElement} from 'react'
import {Box} from '@mui/material'
import MBAILoadingSpinner from './MBAILoadingSpinner'

const BasicLoader: FC = (): ReactElement => (
	<Box
		data-testid="mbai-basic-loader"
		sx={
			{
				width: '100%',
				display: 'flex',
				padding: 2,
				alignItems: 'center',
				justifyContent: 'center',
			}
		}
	>
		<MBAILoadingSpinner />
	</Box>
)

export default BasicLoader
