import type {
	ReactNode,
	FC,
	ReactElement,
	SetStateAction,
	Dispatch,
} from 'react'
import {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo,
} from 'react'

export interface WebSocketContextProps {
	setWebSocketUrl: Dispatch<SetStateAction<string | null>>,
	setData: Dispatch<SetStateAction<{[key: string]: string | number} | null>>,
	data: {[key: string]: string | number} | null,
}

const WebSocketContext = createContext<WebSocketContextProps | null>(null)

const useWebSocketContext = (): WebSocketContextProps | null => useContext(WebSocketContext)

const WebSocketNotificationsProvider: FC<{children?: ReactNode}> = ({children}): ReactElement => {
	const [data, setData] = useState<{[key: string]: string | number} | null>(null)
	const [webSocketUrl, setWebSocketUrl] = useState<string | null>(null)

	const handleSetData = (event: MessageEvent): void => {
		const eventData = JSON.parse(event.data)
		setData(eventData)
	}

	useEffect(() => {
		if (!webSocketUrl) return undefined
		const newWebSocket = new WebSocket(webSocketUrl)

		newWebSocket.addEventListener('message', handleSetData)

		return () => {
			// Cleanup event listeners when the component unmounts
			newWebSocket.removeEventListener('message', handleSetData)
		}
	}, [webSocketUrl])

	return (
		<WebSocketContext.Provider
			value={
				useMemo(() => ({
					setWebSocketUrl,
					data,
					setData,
				}), [setWebSocketUrl, data, setData])
			}
		>
			{children}
		</WebSocketContext.Provider>
	)
}

export {
	WebSocketNotificationsProvider,
	useWebSocketContext,
}
