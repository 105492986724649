import type {FC, ReactElement} from 'react'
import {StrictMode} from 'react'
import {
	Table, TableHead, TableRow, TableCell, TableBody, Skeleton, Card,
} from '@mui/material'

const SignalsTableLoader: FC = (): ReactElement => {
	const rows = ['1', '2', '3', '4', '5']

	return (
		<StrictMode>
			<Card
				id="signals-table-loader"
				data-testid="mbai-signals-table-loader"
				role="loader"
			>
				<Table
					sx={
						{minWidth: 240}
					}
					aria-label="simple table"
				>
					<TableHead>
						<TableRow>
							<TableCell sx={
								{mr: 2, width: '40px'}
							}
							>
								<Skeleton sx={
									{width: '20px', borderRadius: '2px'}
								}
								/>
							</TableCell>
							<TableCell>
								<Skeleton
									variant="rounded"
									sx={
										{width: '120px', height: '12px'}
									}
								/>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							rows.map((row) => (
								<TableRow
									key={row}
									sx={
										{'&:last-child td, &:last-child th': {border: 0}}
									}
								>
									<TableCell scope="row">
										<Skeleton sx={
											{width: '20px', borderRadius: '2px'}
										}
										/>
									</TableCell>
									<TableCell sx={
										{textAlign: 'left'}
									}
									>
										<Skeleton
											variant="rounded"
											sx={
												{
													width: '180px', height: '12px', mb: 1,
												}
											}
										/>
										<Skeleton
											variant="rounded"
											sx={
												{width: '70px', height: '8px'}
											}
										/>
									</TableCell>
								</TableRow>
							))
						}
					</TableBody>
				</Table>
			</Card>
		</StrictMode>
	)
}

export default SignalsTableLoader
