import type {
	ReactNode, FC, ReactElement,
} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
	Box,
	Snackbar,
	Alert,
} from '@mui/material'
import {hideSnackbar} from '../../redux/slices/snackbar'
import type {RootState} from './store'

const MBAISnackBarProvider: FC<{ children: ReactNode }> = ({children}): ReactElement => {
	const open = useSelector((state: RootState) => state.snackbar.open)
	const severity = useSelector((state: RootState) => state.snackbar.severity)
	const message = useSelector((state: RootState) => state.snackbar.message)
	const dispatch = useDispatch()

	const handleClose = (): void => {
		dispatch(hideSnackbar())
	}

	return (
		<Box
			data-testid="mbai-main-snackbar-provider-container"
			data-cy="mbai-main-snackbar-provider-container"
			id="mbai-main-snackbar-provider-container"
		>
			{children}
			<Snackbar
				open={open}
				autoHideDuration={10000}
				data-severity={severity}
				onClose={handleClose}
				data-name="mbai-main-snackbar"
			>
				<Alert
					onClose={handleClose}
					data-cy="mbai-main-snackbar-alert-component"
					severity={severity}
					sx={
						{width: '100%'}
					}
					variant="filled"
				>
					{message}
				</Alert>
			</Snackbar>
		</Box>
	)
}

export default MBAISnackBarProvider
