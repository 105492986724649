import type {
	BaseSyntheticEvent, FC, ReactElement,
} from 'react'
import {StrictMode, useState} from 'react'
import Icon from '@mui/material/Icon'
import Popover from '@mui/material/Popover'
import IconMenu from './IconMenu'

const LineMenu: FC<{lineId: string}> = ({lineId}): ReactElement => {
	const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null)

	const handleClick = (event: BaseSyntheticEvent): void => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	return (
		<StrictMode>
			<Icon
				baseClassName="material-icons-two-tone"
				onClick={handleClick}
				data-testid="mbai-lines-overview-line-menu-component"
				data-cy="mbai-lines-overview-line-menu-component"
				sx={
					{cursor: 'pointer'}
				}
			>
				more_vert
			</Icon>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={
					{
						vertical: 'bottom',
						horizontal: 'right',
					}
				}
				transformOrigin={
					{
						vertical: 'top',
						horizontal: 'right',
					}
				}
			>
				<IconMenu
					lineId={lineId}
				/>
			</Popover>
		</StrictMode>
	)
}

export default LineMenu
