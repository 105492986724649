import {LoadingButton} from '@mui/lab'
import {Box} from '@mui/material/'
import type {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {useCustomTranslation} from '../../../../../shared/hooks/useCustomTranslation'

const AddConnectionEdgeApiNoConnectionButtons:FC = () => {
	const navigate = useNavigate()
	const [t] = useCustomTranslation('edge_api')

	return (
		<Box sx={
			{display: 'flex', gap: 2}
		}
		>
			<LoadingButton
				variant="contained"
				color="primary"
				onClick={() => navigate('/docs/montblanc.ai/getting-started')}
				data-testid="go-to-documentation-button"
			>
				{t('buttons.go_to_documentation')}
			</LoadingButton>
			<LoadingButton
				variant="contained"
				color="primary"
				onClick={() => null}
				data-testid="open-chat-button"
			>
				{t('buttons.open_chat')}
			</LoadingButton>
		</Box>
	)
}

export default AddConnectionEdgeApiNoConnectionButtons
