import {
	Grid, Typography, Divider, Link,
} from '@mui/material'
import type {FC, ReactElement} from 'react'
import type {MqttConnectionEdgeApiInterface} from '../../../../../../shared/interfaces/edgeapi/mqttConnectionEdgeApi'
import {useCustomTranslation} from '../../../../../../shared/hooks/useCustomTranslation'

type Props = {
    mqttData: MqttConnectionEdgeApiInterface,
};

const MQTTDataCard: FC<Props> = ({mqttData}): ReactElement => {
	const [t] = useCustomTranslation('edge_api')

	const fields = [
		{label: t('name'), value: mqttData.name},
		{label: t('description'), value: mqttData.description},
		{label: 'MQTT Host', value: mqttData.mqtt_host},
		{label: 'MQTT Port', value: mqttData.mqtt_port},
		{label: 'MQTT Username', value: mqttData.mqtt_username},
		{label: 'MQTT Shared Access Key', value: mqttData.mqtt_shared_access_key},
		{label: 'MQTT Topic', value: mqttData.mqtt_topic},
		{label: 'MQTT QOS', value: mqttData.mqtt_qos},
		{label: 'MQTT Version', value: mqttData.mqtt_version},
		{label: 'MQTT SSL Enabled', value: mqttData.mqtt_ssl_enabled ? 'Yes' : 'No'},
		{label: 'Tenant ID', value: mqttData.tenant_id},
	]

	const schemaExample = `
{
    "tenant_id": "${mqttData.tenant_id}",
    "measurements": [
        {
            "id": "ecf95c80-4aa3-5be1-8f82-d9480fbbb383",
            "name": "Temperature Sensor",
            "description": "Temperature value in Fahrenheit",
            "unit": "float",
            "data_type": "float",
            "value": "44.37015118019153",
            "timestamp": "2024-11-13T20:52:37.317828Z",
            "status_code": "GOOD",
            "error_msg": ""
        },
        {
            "id": "8db95700-a954-5841-b9e3-846da7af5e1e",
            "name": "Pressure Sensor",
            "description": "Pressure value in Bar",
            "unit": "float",
            "data_type": "int",
            "value": "10",
            "timestamp": "2024-11-13T20:52:37.316903Z",
            "status_code": "BAD",
            "error_msg": "bad connection"
        }
    ]
}`

	return (
		<Grid
			container
			spacing={2}
		>
			{fields.map((field) => (
				<Grid
					item
					xs={12}
					key={field.label}
					container
				>
					<Grid
						item
						xs={4}
					>
						<Typography variant="body1">
							{field.label}
							:
						</Typography>
					</Grid>
					<Grid
						item
						xs={8}
					>
						<Typography
							variant="body1"
							sx={{whiteSpace: 'normal', overflowWrap: 'break-word'}}
						>
							{field.value}
						</Typography>
					</Grid>
				</Grid>
			))}
			<Divider sx={{my: 2}} />
			<Grid
				item
				xs={12}
				container
			>
				<Grid
					item
					xs={4}
				>
					<Typography variant="body1">
						{t('mqtt_message_schema_example')}
						:
					</Typography>
				</Grid>
				<Grid
					item
					xs={8}
				>
					<Typography
						variant="body2"
						sx={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}
					>
						{schemaExample}
					</Typography>
				</Grid>
			</Grid>
			<Divider sx={{my: 2}} />
			<Grid
				item
				xs={12}
			>
				<Typography variant="body2">
					{t('mqtt_schema_more_details')}
					<Link
						href="https://support.montblanc.ai/en/articles/10131062-schema-for-mqtt-connection"
						target="_blank"
						rel="noopener noreferrer"
					>
						{t('official_documentation')}
					</Link>
					.
				</Typography>
			</Grid>
		</Grid>
	)
}

export default MQTTDataCard
