import type {FC, ReactElement} from 'react'
import {Link} from 'react-router-dom'
import {
	Box,
	Toolbar,
	AppBar,
} from '@mui/material'
import AppbarLanguage from './AppbarLanguage'
import ToggleTheme from './ToggleTheme'
import {useLogo} from '../../../shared/hooks/useLogo'

const MinimizedAppbar: FC = (): ReactElement => {
	const {fullLogo} = useLogo()

	return (
		<AppBar
			position="fixed"
			sx={
				{
					backgroundColor: 'background.default',
					borderBottom: 1,
					borderColor: 'divider',
					boxShadow: 'none',
				}
			}
			data-testid="mbai-main-minimized-appbar"
		>
			<Toolbar>
				<Box
					sx={
						{
							flexGrow: 1,
							display: 'flex',
							alignItems: 'center',
						}
					}
				>
					<Link
						className=""
						to="/"
					>
						<img
							src={fullLogo}
							style={
								{maxWidth: '150px'}
							}
							alt="MontBlancAI logo"
						/>
					</Link>
					<Box
						sx={
							{
								flexGrow: 1,
								display: 'flex',
								justifyContent: 'flex-end',
							}
						}
					>
						<Box
							sx={
								{
									mr: 1,
									my: 'auto',
								}
							}
						>
							<ToggleTheme />
						</Box>
						<AppbarLanguage />
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	)
}

export default MinimizedAppbar
