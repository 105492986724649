import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {ConnectionEdgeApiInterface} from '../../../../../shared/interfaces/edgeapi/connectionEgeApi'
import type {MqttConnectionEdgeApiInterface} from '../../../../../shared/interfaces/edgeapi/mqttConnectionEdgeApi'

const initialState = {
	connectionName: '',
	connectionDescription: '',
	identifier: '',
	data: null as ConnectionEdgeApiInterface | null,
	mqttData: undefined as MqttConnectionEdgeApiInterface | undefined,
}

const connectionSlice = createSlice({
	name: 'connection',
	initialState,
	reducers: {
		setConnectionName: (state, action: PayloadAction<string>) => {
			state.connectionName = action.payload
		},
		setConnectionDescription: (state, action: PayloadAction<string>) => {
			state.connectionDescription = action.payload
		},
		setIdentifier: (state, action: PayloadAction<string>) => {
			state.identifier = action.payload
		},
		setData: (state, action: PayloadAction<ConnectionEdgeApiInterface | null>) => {
			state.data = action.payload
		},
		setMqttData: (state, action: PayloadAction<MqttConnectionEdgeApiInterface | undefined>) => {
			state.mqttData = action.payload
		},
		resetConnection: () => initialState,
	},
})

export const {
	setConnectionName, setConnectionDescription, setIdentifier, setData, setMqttData, resetConnection,
} = connectionSlice.actions

export default connectionSlice.reducer
