import type {ReactElement} from 'react'
import {
	Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Paper,
} from '@mui/material'
import {NavLink} from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import BarChartIcon from '@mui/icons-material/BarChart'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'

const IconMenu = ({lineId}: {lineId: string}): ReactElement => {
	const [t] = useCustomTranslation('lines_overview')

	return (
		<Paper
			sx={
				{
					width: 320,
					maxWidth: '100%',
				}
			}
			data-testid="mbai-lines-overview-icon-menu-component"
		>
			<MenuList>
				<NavLink to={`/line/${lineId}`}>
					<MenuItem
						data-name="line-table-view-menu-view-line-button"
						data-cy="mbai-line-table-view-menu-view-line-button"
					>
						<ListItemIcon>
							<BarChartIcon
								fontSize="small"
								color="primary"
							/>
						</ListItemIcon>
						<ListItemText
							sx={
								{color: 'text.secondary'}
							}
						>
							{t('view_line')}
						</ListItemText>
					</MenuItem>
				</NavLink>
				<Divider
					component="li"
					sx={
						{my: 1}
					}
				/>
				<NavLink to={`/line/${lineId}/signals`}>
					<MenuItem
						data-name="line-table-view-menu-view-signals-button"
						data-cy="mbai-line-table-view-menu-view-signals-button"
					>
						<ListItemIcon>
							<TrendingUpIcon
								fontSize="small"
								color="primary"
							/>
						</ListItemIcon>
						<ListItemText
							sx={
								{color: 'text.secondary'}
							}
						>
							{t('view_signals')}
						</ListItemText>
					</MenuItem>
				</NavLink>
				<Divider
					component="li"
					sx={
						{my: 1}
					}
				/>
				<NavLink to={`/line/${lineId}/line_management`}>
					<MenuItem
						data-cy="mbai-line-table-view-menu-view-line-management-button"
					>
						<ListItemIcon>
							<EditIcon
								fontSize="small"
								color="primary"
							/>
						</ListItemIcon>
						<ListItemText
							sx={
								{color: 'text.secondary'}
							}
						>
							{t('edit_line')}
						</ListItemText>
					</MenuItem>
				</NavLink>

			</MenuList>
		</Paper>
	)
}

export default IconMenu
