import en from '../../img/language/US.png'
import de from '../../img/language/DE3.png'
import fr from '../../img/language/PM.png'
import es from '../../img/language/ES.png'

export interface AvailableLanguageInterface {
    id: string,
    name: string,
    label: string,
    image: string,
}

export const availableLanguages: AvailableLanguageInterface[] = [
	{
		id: 'en',
		name: 'English',
		label: 'EN',
		image: en,
	},
	{
		id: 'de',
		name: 'Deutsch',
		label: 'DE',
		image: de,
	},
	{
		id: 'fr',
		name: 'Français',
		label: 'FR',
		image: fr,
	},
	{
		id: 'es',
		name: 'Español',
		label: 'ES',
		image: es,
	},
]
