import {baseApi} from './base'
import type {TenantsInterface} from '../shared/interfaces/tenants'
import type {BaseFetchResultsInterface} from '../shared/interfaces/base'

const tenantsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		createTenant: builder.mutation<TenantsInterface, {body: FormData}>({
			query: ({body}) => ({
				url: 'authorization/tenants/register/',
				method: 'POST',
				body,
			}),
		}),
		getTenants: builder.mutation<TenantsInterface[], void>({
			query: () => ({url: 'authorization/tenants/'}),
			transformResponse: (response: BaseFetchResultsInterface<TenantsInterface>) => response.results,
		}),
	}),
	overrideExisting: false,
})

export const {
	useCreateTenantMutation,
	useGetTenantsMutation,
} = tenantsApi
