import {Box} from '@mui/material/'
import type {FC} from 'react'
import AddConnectionEdgeApiFormPage from './AddConnectionEdgeApiFormPageF'
import RowDataProvider from '../../../shared/rowDataProvider'

const AddConnectionEdgeApiPage: FC = () => (
	<Box
		data-testid="add-connection-edge-api-page"
		sx={
			{
				mx: 'auto', width: '100%', maxWidth: '800px',
			}
		}
	>
		<RowDataProvider>
			<AddConnectionEdgeApiFormPage />
		</RowDataProvider>
	</Box>

)

export default AddConnectionEdgeApiPage
