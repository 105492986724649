import type {FC, ReactElement} from 'react'
import {
	Card, CardHeader, CardContent, Skeleton, Divider,
} from '@mui/material'

const NivoGraphLoader: FC = (): ReactElement => (
	<Card
		data-name="signal-graph-loader"
		data-testid="mbai-signal-graph-loader"
		sx={
			{mb: 2}
		}
	>
		<CardHeader
			action={
				(
					<Skeleton
						variant="rounded"
						width="10px"
					/>
				)
			}
			title={
				(
					<Skeleton
						variant="rounded"
						width="200px"
					/>
				)
			}
		/>
		<Divider />
		<CardContent>
			<Skeleton
				variant="rounded"
				height="275px"
			/>
			<Skeleton
				variant="rounded"
				width="150px"
				height="12px"
				sx={
					{mt: 2, mx: 'auto'}
				}
			/>
		</CardContent>
	</Card>
)

export default NivoGraphLoader
