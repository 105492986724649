import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {UserInterface} from '../../shared/interfaces/user'

const defaultTheme = localStorage.getItem('mbai-preferred-theme')
const defaultLanguage = localStorage.getItem('mbai-preferred-language')
const defaultLocale = localStorage.getItem('mbai-preferred-locale')

interface UserSliceProps {
    object: UserInterface | null,
    preferredLanguage: string,
    preferredTheme: string,
    preferredLocale: string,
}

const initialState: UserSliceProps = {
	object: null,
	preferredLanguage: defaultLanguage as string,
	preferredTheme: defaultTheme as string,
	preferredLocale: defaultLocale as string,
}

export const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<UserInterface | null>) => ({
			...state,
			object: action.payload,
		}),
		setPreferredLanguage: (state, action: PayloadAction<string>) => {
			localStorage.setItem('mbai-preferred-language', action.payload)
			return {
				...state,
				preferredLanguage: action.payload,
			}
		},
		setPreferredTheme: (state, action: PayloadAction<string>) => {
			localStorage.setItem('mbai-preferred-theme', action.payload)
			return {
				...state,
				preferredTheme: action.payload,
			}
		},
		setPreferredLocale: (state, action: PayloadAction<string>) => {
			localStorage.setItem('mbai-preferred-locale', action.payload)
			return {
				...state,
				preferredLocale: action.payload,
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	setUser, setPreferredTheme, setPreferredLanguage, setPreferredLocale,
} = user.actions
export default user.reducer
