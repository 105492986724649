import type {FC, ReactElement} from 'react'
import {Skeleton} from '@mui/material'

interface BasicTextLoaderProps {
    width?: number,
	height?: number,
}

const BasicTextLoader: FC<BasicTextLoaderProps> = (
	{
		width = 400,
		height,
	},
): ReactElement => (
	<Skeleton
		data-testid="mbai-main-basic-text-loader"
		sx={
			{
				width: '100%',
				maxWidth: `${width}px`,
				height: height ? `${height}px` : '20px',
			}
		}
	/>
)

export default BasicTextLoader
