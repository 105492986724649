import type {FC} from 'react'
import {useState, useCallback} from 'react'
import {Box} from '@mui/material/'
import type {UserContextProps} from '../../../App/UserProvider'
import {useUserContext} from '../../../App/UserProvider'
import {useCreateAnomalyDiscussionCommentMutation, useGetAnomalyDiscussionCommentsQuery} from '../../../../apis/anomalies'
import Message from './ChatMessage'
import ScrollbarContainer from '../../../../shared/components/ScrollbarContainer.tsx'
import BasicMessageBox from '../../../../shared/components/base/BasicMessageBox.tsx'
import {useResizeObserver} from '../../../../shared/hooks/useResizeObserver.ts'
import {getFormattedDateWithContext} from '../../../../util-functions.ts'

type Props = {
	mainHeight: string,
    anomalyId: string,
};

const AnomalyChat: FC<Props> = ({anomalyId, mainHeight}) => {
	// Hooks
	const {currentUser} = useUserContext() as UserContextProps

	// States
	const [messageBoxHeight, setMessageBoxHeight] = useState<number>(0)

	// RTL Query
	const {data: anomalyDiscussionComments, refetch} = useGetAnomalyDiscussionCommentsQuery({anomaly_id: anomalyId})
	const [createAnomalyDiscussionComment] = useCreateAnomalyDiscussionCommentMutation()

	const handleAddMessage = useCallback((message: string) => {
		if (message.trim() !== '') {
			createAnomalyDiscussionComment({
				anomaly: anomalyId,
				comment_text: message,
				created_by: currentUser?.id,
			}).unwrap()
				.then(() => {
					refetch()
				})
		}
	}, [anomalyId, createAnomalyDiscussionComment, currentUser?.id, refetch])

	const handleResize = useCallback((entry: ResizeObserverEntry): void => {
		const {target} = entry

		// Get the computed style of the target element
		const computedStyle = getComputedStyle(target)

		// Extract the padding values
		const paddingTop = parseFloat(computedStyle.paddingTop)
		const paddingBottom = parseFloat(computedStyle.paddingBottom)
		const borderTop = parseFloat(computedStyle.borderTopWidth)

		// Update dimensions
		setMessageBoxHeight(entry.contentRect.height + paddingTop + paddingBottom + borderTop)
	}, [])

	const messageBoxContainerRef = useResizeObserver(handleResize)

	return (
		<Box>
			<ScrollbarContainer
				height={`calc(${mainHeight} - ${messageBoxHeight + 45}px)`}
				autoScroll
			>
				<Box
					sx={{p: 2}}
					data-testid="anomaly-chat"
				>
					{anomalyDiscussionComments?.map((comment, index) => (
						<Message
							key={comment.id}
							comment={comment}
							isCurrentUser={currentUser?.id === comment.created_by.id}
							dateCreated={getFormattedDateWithContext(new Date(comment.created_at), currentUser, {
								showYear: false,
								showSeconds: false,
							})}
							lastItem={anomalyDiscussionComments.length - 1 === index}
							onChatMessageUpdate={() => refetch()}
						/>
					))}
				</Box>
			</ScrollbarContainer>
			<Box
				ref={messageBoxContainerRef}
				sx={{
					display: 'flex',
					alignItems: 'flex-end',
					width: '100%',
					borderTop: 1,
					borderColor: 'divider',
					py: 2,
					pl: 2,
					pr: 1.5,
				}}
			>
				<BasicMessageBox
					onMessageUpdated={handleAddMessage}
				/>
			</Box>
		</Box>
	)
}

export default AnomalyChat
