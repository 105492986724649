import type {FC} from 'react'
import FilterSelect from '../../../../helpers/FilterSelect'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'

const AnomalyFilterByStatus: FC = () => {
	const [t] = useCustomTranslation('anomalies')
	const options = [
		{
			key: 'All', value: 'All', label: t('anomaly_feedback_chat.all'),
		},
		{
			key: 'Detected', value: 'DETECTED', label: t('anomaly_feedback_chat.detected'),
		},
		{
			key: 'Classified', value: 'CLASSIFIED', label: t('anomaly_feedback_chat.classified'),
		},
		{
			key: 'Under Review', value: 'UNDER_REVIEW', label: t('anomaly_feedback_chat.under_review'),
		},
	]

	return (
		<FilterSelect
			options={options}
			defaultValue="All"
			paramTitle="status"
			label={t('anomaly_feedback_chat.status_string')}
		/>
	)
}

export default AnomalyFilterByStatus
