import type {
	ReactNode, FC, ReactElement,
} from 'react'
import {
	createContext, useContext, useState, useMemo, useCallback, useEffect,
} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import type {EquipmentInterface} from '../interfaces/equipment.ts'
import {useGetEquipmentsQuery} from '../../apis/base.ts'

interface EquipmentsProviderProps {
    children?: ReactNode,
}

export interface EquipmentsContextProps {
	equipments: EquipmentInterface[] | undefined,
	currentEquipment: EquipmentInterface | null,
	updateCurrentEquipmentId: (currentEquipment: string | null) => void,
	loadingEquipments: boolean,
	equipmentsError: boolean,
	fetchingEquipments: boolean,
	currentEquipmentId: string | null,
}

const EquipmentsContext = createContext<EquipmentsContextProps | null>(null)

const useEquipmentsContext = (): EquipmentsContextProps | null => useContext(EquipmentsContext)

const EquipmentsProvider: FC<EquipmentsProviderProps> = ({children}): ReactElement => {
	// Hooks
	const {id: lineId} = useParams()
	const {
		data: equipments, isLoading: loadingEquipments, isError: equipmentsError, isFetching: fetchingEquipments,
	} = useGetEquipmentsQuery({lineId: lineId as string})
	const initialSearchParams = new URLSearchParams(window.location.search)

	// States
	const [currentEquipment, setCurrentEquipment] = useState<EquipmentInterface | null>(null)
	const [currentEquipmentId, setCurrentEquipmentId] = useState<string | null>(initialSearchParams.get('equipment') || null)
	const [searchParams, setSearchParams] = useSearchParams()

	// Basic Variables
	const viewId = searchParams.get('view')

	// Functions
	const updateCurrentEquipmentId = useCallback((currentEquipmentId: string | null) => {
		const newCurrentEquipment = equipments?.find((equipment) => equipment.id === currentEquipmentId) || null
		if (newCurrentEquipment) {
			setCurrentEquipmentId(newCurrentEquipment.id)
		} else {
			setCurrentEquipmentId(null)
		}
	}, [equipments])

	// Effects
	// When the current equipment changes, update the urlParams
	useEffect(() => {
		const currentUrlParams = new URLSearchParams(window.location.search)
		if (currentEquipmentId) {
			currentUrlParams.set('equipment', currentEquipmentId)
		} else {
			currentUrlParams.delete('equipment')
		}
		setSearchParams(currentUrlParams, {replace: true})
	}, [currentEquipmentId, setSearchParams])

	// If there is a view, set the equipment to none
	useEffect(() => {
		if (viewId) {
			updateCurrentEquipmentId(null)
		}
	}, [viewId, updateCurrentEquipmentId])

	// When currentEquipmentId changes, update the currentEquipment
	useEffect(() => {
		const newCurrentEquipment = equipments?.find((equipment) => equipment.id === currentEquipmentId) || null
		if (newCurrentEquipment) {
			setCurrentEquipment(newCurrentEquipment)
		} else {
			setCurrentEquipment(null)
		}
	}, [currentEquipmentId, equipments])

	return (
		<EquipmentsContext.Provider
			value={
				useMemo(() => ({
					equipments,
					currentEquipment,
					updateCurrentEquipmentId,
					loadingEquipments,
					equipmentsError,
					fetchingEquipments,
					currentEquipmentId,
				}), [
					equipments,
					currentEquipment,
					updateCurrentEquipmentId,
					loadingEquipments,
					equipmentsError,
					fetchingEquipments,
					currentEquipmentId,
				])
			}
		>
			{children}
		</EquipmentsContext.Provider>
	)
}

export {
	useEquipmentsContext, EquipmentsProvider, EquipmentsContext,
}
