import React, {useEffect, Suspense} from 'react'
import {useNavigate} from 'react-router-dom'
import {
	useFetchAccessTokenMutation,
	useLazyFetchUserIdentityQuery,
	isNewAuthSystemEnabled,
} from '../../../apis/auth'
import DashTemplateLoader from '../loaders/dashboards/DashTemplate'
import {useAuthContext} from '../../../containers/App/AuthProvider'

const AuthCallbackPage: React.FC = () => {
	const navigate = useNavigate()
	const [fetchAccessToken] = useFetchAccessTokenMutation()
	const [triggerFetchUserIdentity] = useLazyFetchUserIdentityQuery()
	const {loginUser} = useAuthContext() || {}

	useEffect(() => {
		if (!isNewAuthSystemEnabled) {
			navigate('/login')
			return
		}

		const fetchTokenAndIdentity = async (code: string, codeVerifier: string): Promise<void> => {
			try {
				const tokenResponse = await fetchAccessToken({code, codeVerifier}).unwrap()

				if (tokenResponse.access_token) {
					localStorage.setItem('token', tokenResponse.access_token)
					localStorage.setItem('refresh_token', tokenResponse.refresh_token)
					localStorage.setItem('expires_in', tokenResponse.expires_in.toString())

					const userIdentityResult = await triggerFetchUserIdentity(undefined).unwrap()
					if (userIdentityResult.email) {
						localStorage.setItem('username', userIdentityResult.email)

						if (loginUser) {
							loginUser(tokenResponse.access_token)
						}

						navigate('/')
					} else {
						console.error('Failed to fetch user identity')
					}
				} else {
					console.error('Failed to obtain access token:', tokenResponse)
				}
			} catch (error) {
				if (error instanceof Error && 'status' in error && error.status === 400) {
					console.error('400 error: Invalid authorization code or code verifier')
					navigate('/')
				} else {
					console.error('Error fetching access token:', error)
				}
			}
		}

		const urlParams = new URLSearchParams(window.location.search)
		const code = urlParams.get('code')
		const codeVerifier = sessionStorage.getItem('codeVerifier') || ''

		if (code) {
			fetchTokenAndIdentity(code, codeVerifier)
		} else {
			console.error('Authorization code not found in URL')
		}
	}, [navigate, fetchAccessToken, triggerFetchUserIdentity, loginUser])

	return (
		<Suspense fallback={<DashTemplateLoader />}>
			<DashTemplateLoader />
		</Suspense>
	)
}

export default AuthCallbackPage
