import resources from './resources'
import {getDefaultPreferredLanguage} from '../util-functions'

const defaultLanguage = getDefaultPreferredLanguage()
export const config = {
	interpolation: {escapeValue: false}, // React already does escaping
	lng: defaultLanguage,
	fallbackLng: 'en',
	resources,
}

export {resources}
