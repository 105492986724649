import type {FC} from 'react'
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
	TextField,
	Box,
	Typography,
} from '@mui/material/'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'

type RadioOption = {
	label: string, // Displayed localized text
	value: string, // Internal consistent value
};

type RadioQuestionProps = {
	question: string,
	options: RadioOption[], // Updated to accept an array of objects
	value: string,
	details: string,
	triggerQuestionOption?: string | string[],
	onChange: (value: string) => void,
	onDetailsChange: (details: string) => void,
	error?: boolean,
	disabled?: boolean,
};

const RadioQuestion: FC<RadioQuestionProps> = ({
	question,
	options,
	value,
	details,
	triggerQuestionOption,
	onChange,
	onDetailsChange,
	error = false,
	disabled = false,
}) => {
	const isTriggered = Array.isArray(triggerQuestionOption) ? triggerQuestionOption.includes(value) : value === triggerQuestionOption
	const [t] = useCustomTranslation('anomalies')

	return (
		<Box
			sx={{display: 'flex', flexDirection: 'column'}}
			data-testid="radio-question"
		>
			<FormControl disabled={disabled}>
				<FormLabel>{question}</FormLabel>
				<RadioGroup
					value={value}
					onChange={(e) => onChange(e.target.value)}
				>
					{options.map(({label, value: optionValue}) => (
						<FormControlLabel
							key={optionValue}
							value={optionValue}
							control={<Radio />}
							label={label} // Localized label for the user
						/>
					))}
				</RadioGroup>
				{error && value === '' ? (
					<Typography
						color="error"
						variant="caption"
					>
						{t('anomaly_feedback_chat.this_question_is_required')}
					</Typography>
				) : null}
			</FormControl>
			{isTriggered ? (
				<FormControl sx={{mb: 2}}>
					<FormLabel>
						{t('anomaly_feedback_chat.please_provide_details')}
					</FormLabel>
					<TextField
						type="text"
						value={details}
						onChange={(e) => onDetailsChange(e.target.value)}
						fullWidth
						disabled={disabled}
					/>
				</FormControl>
			) : null}
		</Box>
	)
}

export default RadioQuestion
