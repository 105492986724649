import type {FC} from 'react'
import {
	Box, Divider, LinearProgress, Typography,
} from '@mui/material'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation.ts'
import EquipmentTimeWindowCard from './EquipmentTimeWindowCard.tsx'
import HorizonatlScrollingContainer from '../../../shared/components/HorizontalScrollingContainer.tsx'
import ErrorBoundary from '../../../shared/components/ErrorBoundary.tsx'
import {useSearchForEquipmentTimeWindowBasedOnSignalValueQuery} from '../../../apis/search.ts'

interface SearchEquipmentTimeWindowResultsProps {
    searchTerm: string,
    equipmentSignal: string,
}

const SearchEquipmentTimeWindowResults: FC<SearchEquipmentTimeWindowResultsProps> = (
	{searchTerm, equipmentSignal},
) => {
	const [t] = useCustomTranslation('common')
	const {
		data: equipmentTimeWindowResults,
		error: equipmentTimeWindowError,
		isFetching: equipmentTimeWindowIsFetching,
	} = useSearchForEquipmentTimeWindowBasedOnSignalValueQuery({
		equipmentSignal,
		value: searchTerm,
	}, {skip: !searchTerm})
	return (
		<Box sx={
			{p: 1}
		}
		>
			{
				equipmentTimeWindowIsFetching ? (
					<Box>
						<Divider>
							<Typography
								variant="h6"
								color="text.secondary"
								sx={
									{mb: 1}
								}
							>
								{t(`appbar_search_${equipmentSignal}.search_running`)}
							</Typography>
						</Divider>
						<LinearProgress
							sx={
								{width: '100%'}
							}
						/>
					</Box>
				) : equipmentTimeWindowResults && equipmentTimeWindowResults.length === 0 && !equipmentTimeWindowError ? (
					<Divider>
						<Typography
							variant="h6"
							color="text.secondary"
						>
							{t(`appbar_search_${equipmentSignal}.no_results`)}
						</Typography>
					</Divider>
				) : equipmentTimeWindowResults && !equipmentTimeWindowError ? (
					<>
						<Divider>
							<Typography
								variant="h6"
								color="text.secondary"
								sx={
									{mb: 1}
								}
							>
								{equipmentTimeWindowResults.length}
								{' '}
								{t(`appbar_search_${equipmentSignal}.number_found`)}
							</Typography>
						</Divider>
						<HorizonatlScrollingContainer>
							{
								equipmentTimeWindowResults.map((equipmentTimeWindowResult) => (
									<Box
										key={`appbar-search-${equipmentSignal}-equipment-${equipmentTimeWindowResult.machine_sub_system}`}
										sx={
											{
												flex: '0 0 auto',
												width: 500,
												padding: 1,
											}
										}
									>
										<ErrorBoundary>
											<EquipmentTimeWindowCard
												equipmentTimeWindow={equipmentTimeWindowResult}
											/>
										</ErrorBoundary>
									</Box>
								))
							}
						</HorizonatlScrollingContainer>
					</>
				) : t('messages.unexpected_error')
			}
		</Box>
	)
}

export default SearchEquipmentTimeWindowResults
