/*
 * This file is copied from https://github.com/mui/material-ui/issues/8289
 * It helps ensure shadows are always consistent throughout a layout
 */
import type {Shadows} from '@mui/material'

const shadowKeyUmbraOpacity = 0.05
const shadowKeyPenumbraOpacity = 0.04
const shadowAmbientShadowOpacity = 0.03

const createShadow = (...px: number[]): string => [
	`${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0,0,0,${shadowKeyUmbraOpacity})`,
	`${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0,0,0,${shadowKeyPenumbraOpacity})`,
	`${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(0,0,0,${shadowAmbientShadowOpacity})`,
].join(',')

// Values from https://github.com/material-components/material-components-web/blob/be8747f94574669cb5e7add1a7c54fa41a89cec7/packages/mdc-elevation/_variables.scss
const shadows: Shadows = [
	'none',
	createShadow(0, 2, 10, -1, 0, 1, 1, 0, 0, 1, 3, 0),
	createShadow(0, 3, 10, -2, 0, 2, 2, 0, 0, 1, 5, 0),
	createShadow(0, 3, 30, -2, 0, 3, 4, 0, 0, 1, 8, 0),
	createShadow(0, 2, 40, -1, 0, 4, 5, 0, 0, 1, 10, 0),
	createShadow(0, 3, 50, -1, 0, 5, 8, 0, 0, 1, 14, 0),
	createShadow(0, 3, 50, -1, 0, 6, 10, 0, 0, 1, 18, 0),
	createShadow(0, 4, 50, -2, 0, 7, 10, 1, 0, 2, 16, 1),
	createShadow(0, 5, 50, -3, 0, 8, 10, 1, 0, 3, 14, 2),
	createShadow(0, 5, 60, -3, 0, 9, 12, 1, 0, 3, 16, 2),
	createShadow(0, 6, 60, -3, 0, 10, 14, 1, 0, 4, 18, 3),
	createShadow(0, 6, 70, -4, 0, 11, 15, 1, 0, 4, 20, 3),
	createShadow(0, 7, 80, -4, 0, 12, 17, 2, 0, 5, 22, 4),
	createShadow(0, 7, 80, -4, 0, 13, 19, 2, 0, 5, 24, 4),
	createShadow(0, 7, 90, -4, 0, 14, 21, 2, 0, 5, 26, 4),
	createShadow(0, 8, 90, -5, 0, 15, 22, 2, 0, 6, 28, 5),
	createShadow(0, 8, 100, -5, 0, 16, 24, 2, 0, 6, 30, 5),
	createShadow(0, 8, 110, -5, 0, 17, 26, 2, 0, 6, 32, 5),
	createShadow(0, 9, 110, -5, 0, 18, 28, 2, 0, 7, 34, 6),
	createShadow(0, 9, 120, -6, 0, 19, 29, 2, 0, 7, 36, 6),
	createShadow(0, 10, 130, -6, 0, 20, 31, 3, 0, 8, 38, 7),
	createShadow(0, 10, 130, -6, 0, 21, 33, 3, 0, 8, 40, 7),
	createShadow(0, 10, 140, -6, 0, 22, 35, 3, 0, 8, 42, 7),
	createShadow(0, 11, 140, -7, 0, 23, 36, 3, 0, 9, 44, 8),
	createShadow(0, 11, 150, -7, 0, 24, 38, 3, 0, 9, 46, 8),
]

export default shadows
