import React, {useEffect, Suspense} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import type {AuthContextProps} from '../../../containers/App/AuthProvider'
import {useAuthContext} from '../../../containers/App/AuthProvider'
import {createCodeChallenge, createCodeVerifier} from '../../../utils/authUtils'
import DashTemplateLoader from '../loaders/dashboards/DashTemplate'

const CLIENT_ID = import.meta.env.VITE_APP_AUTH_CLIENT_ID || ''
const REDIRECT_URI = import.meta.env.VITE_APP_CALLBACK_URI || ''
const AUTH_PROVIDER_BASE_URI = import.meta.env.VITE_AUTH_PROVIDER_URL || ''

interface AuthRedirectProps {
    children?: React.ReactNode,
}

const AuthRedirect: React.FC<AuthRedirectProps> = ({children}) => {
	const navigate = useNavigate()
	const location = useLocation()
	const {userIsLoggedIn} = useAuthContext() as AuthContextProps

	useEffect(() => {
		const redirectToAuthProvider = async (): Promise<void> => {
			const codeVerifier = createCodeVerifier()
			const codeChallenge = await createCodeChallenge(codeVerifier)
			sessionStorage.setItem('codeVerifier', codeVerifier)

			const authUrl = `${AUTH_PROVIDER_BASE_URI}/auth/api/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&code_challenge=${codeChallenge}&code_challenge_method=S256`

			window.location.href = authUrl
		}

		if (!userIsLoggedIn && location.pathname !== '/authorize-callback') {
			redirectToAuthProvider()
		}
	}, [userIsLoggedIn, navigate, location.pathname])

	return (
		<Suspense fallback={<DashTemplateLoader />}>
			{userIsLoggedIn ? children : <DashTemplateLoader />}
		</Suspense>
	)
}

export default AuthRedirect
