import type {FC, ReactElement} from 'react'
import {useTheme} from '@mui/material/styles'
import type {ProductionTimelineAnomalyInterface} from '../../../interfaces/anomaly.ts'

interface D3ProductionTimelineRectProps {
    keyProp: string,
    x: number,
    y: number,
    width: number,
    rectHeight: number,
    fill: string,
	hours: number,
	onMouseEnter: () => void,
	onMouseLeave: () => void,
	onClick?: (value: string) => void,
	mainKey: string,
	anomalies?: ProductionTimelineAnomalyInterface[],
	vertical?: boolean,
}

const ProductionTimelineRect: FC<D3ProductionTimelineRectProps> = ({
	keyProp,
	x,
	y,
	width,
	rectHeight,
	fill,
	hours,
	onMouseEnter,
	onMouseLeave,
	onClick,
	mainKey,
	anomalies,
	vertical,
}): ReactElement => {
	// Hooks
	const theme = useTheme()

	// Functions
	const handleMouseEnter = (): void => {
		onMouseEnter()
	}

	const handleMouseLeave = (): void => {
		onMouseLeave()
	}

	// Calculate the center position for the text
	const textX = x + width / 2
	const textY = y + (rectHeight / 2) // Adjust 5 for vertical alignment

	return (
		<g>
			<rect
				key={keyProp}
				x={x}
				y={y}
				width={width}
				height={rectHeight}
				fill={fill}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				rx="3"
				ry="3"
				stroke={anomalies && anomalies.length > 0 ? theme.palette.warning.main : 'rgba(0,0,0,.5)'}
				strokeWidth={anomalies && anomalies.length > 0 ? 2 : 1}
				onClick={() => (onClick ? onClick(mainKey) : null)}
				style={
					{cursor: onClick ? 'pointer' : 'default'}
				}
				data-cy="mbai-production-timeline-rect"
			/>
			<text
				x={textX}
				y={textY}
				textAnchor="middle"
				fill={fill === 'rgba(0, 0, 0, 0.38)ff' ? 'rgba(255, 255, 255,.9)' : theme.palette.text.secondary} // Text color
				fontSize={width > 30 ? '10px' : width <= 30 && width > 20 ? '8px' : '6px'} // Adjust as necessary
				dy=".35em" // To center the text vertically
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={() => (onClick ? onClick(mainKey) : null)}
				style={
					{cursor: onClick ? 'pointer' : 'default'}
				}
			>
				{!vertical && width > 30 ? `${hours.toFixed(1)}h` : vertical && rectHeight > 20 ? `${hours.toFixed(1)}h` : null}
			</text>
			{
				anomalies && anomalies.length > 0 && width > 30 ? (
					<image
						href="/static/warning-in-triangle-symbol-and-sign-png.webp" // Specify the image source path here
						x={x + 5} // Set the x position of the image
						y={y + 5} // Set the y position of the image
						width={15} // Set the width of the image
						height={15} // Set the height of the image
					/>
				) : null
			}
		</g>
	)
}

export default ProductionTimelineRect
