import {useDispatch} from 'react-redux'
import type {AlertColor} from '@mui/material'
import {showSnackbar} from '../../redux/slices/snackbar'

export const useSnackbar = (): [(snackbar: { message: string, severity: AlertColor | undefined, action?: string }) => void] => {
	const dispatch = useDispatch()
	const showSnackbarFn = (snackbar: { message: string, severity: AlertColor | undefined, action?: string }): void => {
		const action = snackbar.action ? snackbar.action : ''
		dispatch(showSnackbar({
			message: snackbar.message,
			severity: snackbar.severity,
			action,
		}))
	}
	return [showSnackbarFn]
}
