import {
	type FC, type ReactElement, useState, useCallback, type BaseSyntheticEvent,
} from 'react'
import {
	Box, IconButton, Tooltip,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import BasicInput from '../BasicInput.tsx'
import {useCustomTranslation} from '../../hooks/useCustomTranslation.ts'

interface BasicMessageBoxProps {
	onMessageUpdated: (message: string) => void,
	defaultValue?: string,
	updating?: boolean,
}
const BasicMessageBox: FC<BasicMessageBoxProps> = ({
	onMessageUpdated, defaultValue, updating = false,
}): ReactElement => {
	// States
	const [message, setMessage] = useState<string>(defaultValue || '')
	const [t] = useCustomTranslation('anomalies')
	// Functions
	const handleAddMessage = useCallback((message: string): void => {
		onMessageUpdated(message)
		setMessage('')
	}, [onMessageUpdated])

	// If we're in updating mode, let's send message updates each time the form changes
	const updateMessage = (event: BaseSyntheticEvent): void => {
		const {value} = event.target
		setMessage(value)
		if (updating) {
			onMessageUpdated(value)
		}
	}

	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%',
				alignItems: 'flex-end',
				position: 'relative',
			}}
			data-testid="mbai-basic-message-box"
		>
			<BasicInput
				placeholder={t('anomaly_feedback_chat.type_a_new_message')}
				variant="outlined"
				fullWidth
				multiline
				minRows={1}
				maxRows={4}
				value={message}
				onChange={updateMessage}
				displayLabel={false}
				whiteBackground={updating}
			/>
			{
				!updating ? (
					<Tooltip
						title={t('anomaly_feedback_chat.send')}
					>
						<IconButton
							onClick={() => handleAddMessage(message)}
							color="primary"
							sx={{ml: 1}}
						>
							<SendIcon />
						</IconButton>
					</Tooltip>
				) : null
			}
		</Box>
	)
}

export default BasicMessageBox
