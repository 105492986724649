import {type ReactElement, type FC} from 'react'
import {Box} from '@mui/material'
import AnomalyDetailsText from './AnomalyDetailsText.tsx'
import {getFormattedDate} from '../../../../util-functions.ts'
import type {UserContextProps} from '../../../App/UserProvider.tsx'
import {useUserContext} from '../../../App/UserProvider.tsx'
import type {AnomalyWithReasonInterface} from '../../../../shared/interfaces/anomaly.ts'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation.ts'

interface AnomalyDiscussionDetailsProps {
	row: AnomalyWithReasonInterface,
}

const AnomalyDiscussionDetails: FC<AnomalyDiscussionDetailsProps> = ({row}): ReactElement => {
	const [t] = useCustomTranslation('anomalies')
	// Hooks
	const {currentUser} = useUserContext() as UserContextProps

	return (
		<Box
			data-testid="anomaly-discussion-details"
		>
			<AnomalyDetailsText
				detail={t('anomaly_feedback_chat.program_name')}
				value={row.program.name}
			/>
			<AnomalyDetailsText
				detail={t('anomaly_feedback_chat.batch_name')}
				value={row.batch.name}
			/>
			{
				row.start && row.end ? (
					<AnomalyDetailsText
						detail={t('anomaly_feedback_chat.time_window')}
						value={`${getFormattedDate(row.start, currentUser, {
							showDate: true, showYear: true, showTime: true,
						})} - ${getFormattedDate(row.end, currentUser, {
							showDate: true, showYear: true, showTime: true,
						})}`}
					/>
				) : null
			}
			<AnomalyDetailsText
				detail={t('anomaly_feedback_chat.step')}
				value={row.step?.toString() as string}
			/>
			<AnomalyDetailsText
				detail={t('anomaly_feedback_chat.reason')}
				value={row.reason}
			/>
		</Box>
	)
}

export default AnomalyDiscussionDetails
