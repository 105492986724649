import {baseApi} from './base'
import type {BaseFetchResultsInterface} from '../shared/interfaces/base'
import type {
	AnomalyWithReasonInterface, AnomalyDiscussionCommentsInterface, AnomalyFeedbackInterface,
} from '../shared/interfaces/anomaly'

const anomaliesApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getAnomalies: builder.query<BaseFetchResultsInterface<AnomalyWithReasonInterface>, {programId?: string, machineId?: string,
			machineSubSystemId?: string, offset: number, limit: number, status?:string, }>({
				query: ({
					programId, machineId, machineSubSystemId, offset, limit, status,
				}) => {
					const queryParameters = []
					if (programId) queryParameters.push(`batch__program=${programId}`)
					if (machineId) queryParameters.push(`batch__machine=${machineId}`)
					if (machineSubSystemId) queryParameters.push(`batch__program__machine_sub_system=${machineSubSystemId}`)
					if (status) queryParameters.push(`status=${status}`)
					queryParameters.push(`offset=${offset}`)
					queryParameters.push(`limit=${limit}`)

					const queryString = queryParameters.join('&')
					const url = `anomalies/?${queryString}`

					return {url}
				},
				transformResponse: (response: BaseFetchResultsInterface<AnomalyWithReasonInterface>) => response,
				extraOptions: {refetchOnMountOrArgChange: true},
			}),
		getAnomalyById: builder.query<AnomalyWithReasonInterface, string>({query: (id) => `anomalies/${id}/`}),
		getAnomalyDiscussionComments: builder.query<AnomalyDiscussionCommentsInterface[], { anomaly_id: string }>({
			query: ({anomaly_id}) => `anomaly-discussion-comments/?anomaly_id=${anomaly_id}`,
			transformResponse: (response: BaseFetchResultsInterface<AnomalyDiscussionCommentsInterface>) => response.results,
		}),
		getAnomalyFeedback: builder.query<AnomalyFeedbackInterface[], { anomaly_id: string }>({
			query: ({anomaly_id}) => `anomaly-feedback/?anomaly_id=${anomaly_id}`,
			transformResponse: (response: BaseFetchResultsInterface<AnomalyFeedbackInterface>) => response.results,
		}),
		createAnomalyDiscussionComment: builder.mutation({
			query: (body) => ({
				url: 'anomaly-discussion-comments/',
				method: 'POST',
				body,
			}),
		}),
		createAnomalyFeedback: builder.mutation({
			query: (body) => ({
				url: 'anomaly-feedback/',
				method: 'POST',
				body,
			}),
		}),
		updateAnomalyDiscussionComment: builder.mutation({
			query: ({id, ...body}) => ({
				url: `anomaly-discussion-comments/${id}/`,
				method: 'PUT',
				body,
			}),
		}),
		deleteAnomalyDiscussionComment: builder.mutation({
			query: (id) => ({
				url: `anomaly-discussion-comments/${id}/`,
				method: 'DELETE',
			}),
		}),
		updateAnomalyFeedback: builder.mutation({
			query: ({id, ...body}) => ({
				url: `anomaly-feedback/${id}/`,
				method: 'PUT',
				body,
			}),
		}),
		deleteAnomalyFeedback: builder.mutation({
			query: (id) => ({
				url: `anomaly-feedback/${id}/`,
				method: 'DELETE',
			}),
		}),
		updateAnomalyLimitedFields: builder.mutation({
			query: ({id, ...body}) => ({
				url: `anomalies/${id}/status-update/`,
				method: 'PATCH',
				body,
			}),
		}),

	}),
	overrideExisting: false,
})

export const {
	useGetAnomaliesQuery,
	useLazyGetAnomaliesQuery,
	useGetAnomalyByIdQuery,
	useGetAnomalyDiscussionCommentsQuery,
	useGetAnomalyFeedbackQuery,
	useCreateAnomalyDiscussionCommentMutation,
	useCreateAnomalyFeedbackMutation,
	useUpdateAnomalyDiscussionCommentMutation,
	useDeleteAnomalyDiscussionCommentMutation,
	useUpdateAnomalyFeedbackMutation,
	useDeleteAnomalyFeedbackMutation,
	useUpdateAnomalyLimitedFieldsMutation,
} = anomaliesApi
