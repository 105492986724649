import type {FC, ReactElement} from 'react'
import {useEffect, useMemo} from 'react'
import {
	Typography, Box, Button, Stepper, Step, StepLabel, StepContent,
} from '@mui/material'
import {ValidatorForm} from 'react-material-ui-form-validator'
import {useSearchParams, NavLink} from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {useDispatch, useSelector} from 'react-redux'
import {useSnackbar} from '../../../../../../shared/hooks/useSnackbar'
import {useCustomTranslation} from '../../../../../../shared/hooks/useCustomTranslation'
import {useRowData} from '../../../shared/rowDataContext'
import ScrollbarContainer from '../../../../../../shared/components/ScrollbarContainer'
import StepContentEdgeApiForm from '../../../utils/hooks/StepContentEdgeApiForm'
import {useSteps} from '../../../utils/stepsEdgeApi'
import useStepNavigation from '../../../utils/hooks/UseStepNavigation'
import {useCreateConnectionMQTTMutation, useUpdateConnectionByIdentifierEdgeApiMutation} from '../../../../../../apis/edgeAPI'
import type {UserContextProps} from '../../../../../App/UserProvider'
import {useUserContext} from '../../../../../App/UserProvider'
import type {RootState} from '../../../../../App/store'
import {setMqttData, resetConnection} from '../../../redux/slices/edgeApiConnectionFormSlice'
import {
	setActiveStep, setOnlineThreeMinutes, setSteps, addSteps, resetNavigation,
} from '../../../redux/slices/edgeApiNavigationFormSlice'
import type {StepsInterface} from '../../../../../../shared/interfaces/steps.ts'

const AddConnectionEdgeApiFormPage: FC = (): ReactElement => {
	const [showNotification] = useSnackbar()
	const [searchParams, setSearchParams] = useSearchParams()
	const [t] = useCustomTranslation('edge_api')
	const {rowData, refetch} = useRowData()
	const {
		connectionName,
		connectionDescription,
		identifier,
		data,
	} = useSelector((state: RootState) => state.edgeApiConnectionForm)
	const {activeStep, steps} = useSelector((state: RootState) => state.edgeApiNavigationForm)
	const connectionData = useMemo(() => rowData.find((connection) => connection.id === data?.id) || null, [rowData, data, searchParams])
	const stepLibrary = useSteps()
	const [createMQTTConnection] = useCreateConnectionMQTTMutation()
	const {currentUser} = useUserContext() as UserContextProps
	const [updateConnection] = useUpdateConnectionByIdentifierEdgeApiMutation()
	const dispatch = useDispatch()
	const {handleNext, buttonsToRender} = useStepNavigation()

	const handleInitialSetup = (): void => {
		const deviceTypeUrl = searchParams.get('device_type')

		if (!deviceTypeUrl) {
			dispatch(setActiveStep(0))
		}
		if (activeStep === 1 || (deviceTypeUrl && activeStep === 0)) {
			if (activeStep === 0) {
				dispatch(setActiveStep(1))
			}

			if (deviceTypeUrl === 'snap_edge') {
				dispatch(setSteps(stepLibrary.steps201))
			} else {
				dispatch(setSteps(stepLibrary.stepsMqtt))
			}
		}
	}

	const updateUnableToConnectSteps = (): void => {
		const updatedSteps = steps.map((step: StepsInterface, index: number) => {
			if (index === 5) {
				return {
					...step,
					description: 'stepper.step_unable_to_connect.description',
				}
			}
			return step
		})
		dispatch(setSteps(updatedSteps))
	}

	const handleOnlineStatusCheck = (): void => {
		if (activeStep === 5 && searchParams.get('device_type') === 'snap_edge') {
			setTimeout(() => {
				if (connectionData?.online_status === 'connected') {
					dispatch(setOnlineThreeMinutes(true))
					showNotification({
						message: t('notifications.connection_succesful'),
						severity: 'success',
					})
					dispatch(addSteps(stepLibrary.stepsIfConnectionTrue))
					handleNext()
				} else {
					dispatch(setOnlineThreeMinutes(false))
					showNotification({
						message: t('notifications.having_trouble'),
						severity: 'error',
					})
					updateUnableToConnectSteps()
				}
			}, 5000)
		}
	}

	const handleDataChange = (): void => {
		if (data) {
			const updatedSteps = steps.map((step: StepsInterface, index: number) => {
				if (index === 4) {
					return {
						...step,
						description: data?.cellular_available ? 'stepper.step_four.description_cellular_available' : 'stepper.step_four.description_cellular_unavailable',
					}
				}
				return step
			})
			dispatch(setSteps(updatedSteps))

			const newSearchParams = new URLSearchParams(searchParams)
			newSearchParams.set('id', data.id)
			setSearchParams(newSearchParams)
		}
	}

	useEffect(() => {
		handleInitialSetup()
		handleOnlineStatusCheck()
		handleDataChange()
	}, [activeStep, searchParams.get('device_type'), data])

	const getStepContent = (stepIndex: number): ReactElement => (
		<StepContentEdgeApiForm
			stepIndex={stepIndex}
		/>
	)

	const handleCreateMQTT = async (): Promise<void> => {
		try {
			const response = await createMQTTConnection({
				name: connectionName,
				description: connectionDescription,
				tenantId: currentUser?.userprofile?.object_id || '',
			}).unwrap()
			showNotification({
				message: t('notifications.succesfully_added_connection'),
				severity: 'success',
			})
			handleNext()
			refetch()
			dispatch(setMqttData(response))
		} catch (error) {
			showNotification({
				message: t('notifications.error'),
				severity: 'error',
			})
		}
	}

	const handleAddConnection = async (): Promise<void> => {
		if (data) {
			try {
				await updateConnection({
					identifier,
					body: {
						name: connectionName,
						description: connectionDescription,
						tenant_id: currentUser?.userprofile?.object_id,
					},
				}).unwrap()
					.then(() => {
						showNotification({
							message: t('notifications.succesfully_added_connection'),
							severity: 'success',
						})
						handleNext()
						refetch()
					})
			} catch (error) {
				showNotification({
					message: t('notifications.error'),
					severity: 'error',
				})
			}
		}
	}

	const handleBackClickToConnectionsTable = (): void => {
		dispatch(resetConnection())
		dispatch(resetNavigation())
	}

	return (
		<Box
			data-testid="mbai-add-connection-edge-api-form-page"
			sx={
				{mt: 2}
			}
		>
			<Box
				sx={
					{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						mb: 2.5,

					}
				}
			>
				<Typography
					variant="h2"
					component="h1"
				>
					{t('add_your_connection')}
				</Typography>
				<Button
					component={NavLink}
					onClick={handleBackClickToConnectionsTable}
					to="/connections"
					variant="outlined"
					startIcon={<ArrowBackIcon />}
				>
					{t('buttons.back')}
				</Button>
			</Box>
			<ScrollbarContainer
				height="calc(100vh - 138px)"
			>
				<Stepper
					activeStep={activeStep}
					orientation="vertical"
					sx={
						{mb: 3}
					}
				>
					{
						steps.map((item: {label: string, description: string}, index: number) => (
							<Step key={item.label}>
								<StepLabel>{t(item.label)}</StepLabel>
								<StepContent
									sx={
										{pr: 0}
									}
								>
									{
										item.description ? (
											<Typography variant="body1">{t(item.description)}</Typography>
										) : null
									}
									<ValidatorForm onSubmit={handleAddConnection}>
										{getStepContent(index)}
										{buttonsToRender(handleAddConnection, handleCreateMQTT)}
									</ValidatorForm>
								</StepContent>
							</Step>
						))
					}
				</Stepper>
			</ScrollbarContainer>
		</Box>
	)
}

export default AddConnectionEdgeApiFormPage
