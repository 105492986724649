import {createContext, useContext} from 'react'
import type {ConnectionEdgeApiInterface} from '../../../../shared/interfaces/edgeapi/connectionEgeApi'

interface RowDataContextType {
  rowData: ConnectionEdgeApiInterface[],
  setRowData: (data: ConnectionEdgeApiInterface[]) => void,
  refetch: () => void,
}

const defaultContextValue: RowDataContextType = {
	rowData: [],
	setRowData: () => {},
	refetch: () => {},
}

export const RowDataContext = createContext<RowDataContextType>(defaultContextValue)

export const useRowData = (): RowDataContextType => useContext(RowDataContext)
