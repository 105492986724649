import type {FC, ReactElement} from 'react'
import type {SxProps, Theme} from '@mui/material'
import {
	Box, Card, CardContent, CardHeader, Typography,
} from '@mui/material'
import {useCustomTranslation} from '../../hooks/useCustomTranslation.ts'

interface CardViewErrorProps {
	sx?: SxProps<Theme>, // MUI SxProps type for styling
}

const CardViewError: FC<CardViewErrorProps> = ({sx}): ReactElement => {
	const [t] = useCustomTranslation('errors')

	return (
		<Card
			data-testid="mbai-lines-overview-card-view-error-component"
			sx={sx}
		>
			<CardHeader
				title={
					(
						<Typography
							variant="h3"
						>
							{t('unknown')}
						</Typography>
					)
				}
			/>
			<CardContent
				sx={
					{
						borderTop: 1,
						borderTopColor: 'divider',
					}
				}
			>
				<Box>
					<Typography
						variant="body1"
					>
						{t('error_boundary_generic')}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	)
}

export default CardViewError
