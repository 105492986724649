import type {FC, ReactElement} from 'react'
import {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {Typography} from '@mui/material/'
import {useCustomTranslation} from '../hooks/useCustomTranslation.ts'

const PageTitle: FC = (): ReactElement => {
	const [title, setTitle] = useState<string>('')
	const location = useLocation()
	const [tBatchTraceability] = useCustomTranslation('batch_traceability')
	const [tEdgeApi] = useCustomTranslation('edge_api')
	const [tSettings] = useCustomTranslation('settings')
	const [tMashineSignals] = useCustomTranslation('line_signals')
	const [tCommon] = useCustomTranslation('common')
	const [tDashboards] = useCustomTranslation('dashboards')
	const [tLogin] = useCustomTranslation('login')
	const [tLineManagement] = useCustomTranslation('line_management')

	useEffect(() => {
		const path = location.pathname
		if (path.includes('/lines_overview') || path === '/') {
			setTitle(tCommon('home'))
		} else if (path.includes('/line') && !path.includes('signals') && !path.includes('line_management')) {
			setTitle(tCommon('line_dashboard'))
		} else if (path.includes('/line') && path.includes('signals')) {
			setTitle(tMashineSignals('page_title'))
		} else if (path.includes('/batch_traceability')) {
			setTitle(tBatchTraceability('title'))
		} else if (path.includes('/dashboards')) {
			setTitle(tDashboards('dashboards'))
		} else if (path.includes('/connections')) {
			setTitle(tEdgeApi('connections'))
		} else if (path.includes('/settings')) {
			setTitle(tSettings('title'))
		} else if (path.includes('/create-organization')) {
			setTitle(tLogin('create_organization'))
		} else if (path.includes('/line') && path.includes('/line_management')) {
			setTitle(tLineManagement('page_title'))
		} else if (path.includes('/all-signals')) {
			setTitle(tCommon('signals.plural'))
		} else if (path.includes('/equipment_management')) {
			setTitle(tLineManagement('equipment_management.title'))
		} else if (path.includes('/equipment') && !path.includes('equipment_management')) {
			setTitle(tLineManagement('equipment_dashboard.title'))
		}
	}, [
		location.pathname,
		tBatchTraceability,
		tEdgeApi,
		tSettings,
		tMashineSignals,
		tDashboards,
		tLogin,
		tLineManagement,
		tCommon,
	])

	return (
		<Typography
			variant="h3"
			component="h1"
			gutterBottom
			data-testid="mbai-main-page-title"
			sx={
				{
					color: 'text.primary',
					m: 0,
					fontFamily: 'Circular Black',
					whiteSpace: 'nowrap',
				}
			}
		>
			{title}
		</Typography>
	)
}

export default PageTitle
