import type {FC, ReactElement} from 'react'
import {StrictMode, useCallback} from 'react'
import {
	Box,
	CssBaseline,
} from '@mui/material'
import MBAIMainDrawer from './drawer/Drawer'

interface MBAIMainSideMenuProps {
	open: boolean,
	onMenuOpen: () => void,
}

const MBAIMainSideMenu: FC<MBAIMainSideMenuProps> = ({onMenuOpen, open}): ReactElement => {
	const toggleDrawer = useCallback(() => {
		onMenuOpen()
	}, [onMenuOpen])

	return (
		<StrictMode>
			<Box
				sx={
					{display: 'flex'}
				}
				data-testid="mbai-main-layout-component"
			>
				<CssBaseline />
				<MBAIMainDrawer
					open={open}
					onDrawerOpen={toggleDrawer}
				/>
			</Box>
		</StrictMode>
	)
}

export default MBAIMainSideMenu
