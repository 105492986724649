import type {FC, ReactElement} from 'react'
import {Box, Skeleton} from '@mui/material'
import BasicDashboardContainer from '../../BasicDashboardContainer'
import SignalsTableLoader from '../SignalsTable'

const DashboardManagementLoader: FC = (): ReactElement => (
	<BasicDashboardContainer>
		<Box
			data-testid="mbai-main-dashboard-management-page-loader"
			sx={
				{
					width: '100%',
					height: '100vh',
				}
			}
		>
			<Skeleton
				sx={
					{
						width: '100%',
						maxWidth: '400px',
					}
				}
			/>
			<Box
				sx={
					{mt: 4}
				}
			>
				<SignalsTableLoader />
			</Box>
		</Box>
	</BasicDashboardContainer>
)

export default DashboardManagementLoader
