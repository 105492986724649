import {type FC, type ReactElement} from 'react'
import {Typography, Box} from '@mui/material'

interface AnomalyDetailsTextProps {
	detail: string,
	value: string,
}
const AnomalyDetailsText: FC<AnomalyDetailsTextProps> = ({detail, value}): ReactElement => (
	<Box
		sx={{display: 'flex', alignItems: 'flex-end'}}
		data-testid="anomaly-details-text"
	>
		<Typography
			variant="caption"
			sx={{color: 'text.disabled'}}
		>
			{detail}
		</Typography>
		<Typography
			sx={{ml: 1}}
		>
			{value}
		</Typography>
	</Box>
)

export default AnomalyDetailsText
