import type {ReactElement} from 'react'
import {StrictMode} from 'react'
import {NavLink, Outlet} from 'react-router-dom'
import {
	Box, Typography, Chip, Toolbar,
} from '@mui/material'
import MinimizedAppbar from '../../containers/Layout/appbar/MinimizedAppbar'
import {useCustomTranslation} from '../hooks/useCustomTranslation.ts'
import ErrorBoundary from '../components/ErrorBoundary'

const MinimizedTemplate = (): ReactElement => {
	const [tErrors] = useCustomTranslation('errors')
	return (
		<StrictMode>
			<Box
				component="main"
				data-testid="mbai-main-minimized-template-component"
				data-cy="mbai-main-minimized-template-component"
			>
				<Box sx={
					{display: 'flex'}
				}
				>
					<MinimizedAppbar />
				</Box>
				<Toolbar />
				<Toolbar
					sx={
						{width: '100%', flexGrow: 1}
					}
				>
					<ErrorBoundary
						fallbackComponent={
							(
								<Typography
									variant="body1"
									color="error"
								>
									{tErrors('error_boundary_generic')}
								</Typography>
							)
						}
					>
						<Outlet />
					</ErrorBoundary>
				</Toolbar>
			</Box>
			<Chip
				variant="outlined"
				size="small"
				sx={
					{
						position: 'fixed',
						right: '16px',
						bottom: '16px',
					}
				}
				label={
					(
						<>
							<Typography
								variant="caption"
								sx={
									{
										color: 'text.disabled',
										mr: 0.5,
									}
								}
							>
								Powered by
							</Typography>
							<NavLink
								to="http://montblanc.ai?utm_campaign=Order%20Flow%20Assistant&utm_source=Footer%20on%20Forms"
								target="_blank"
							>
								MontBlancAI
							</NavLink>
						</>
					)
				}
			/>
		</StrictMode>
	)
}

export default MinimizedTemplate
