import type {FC} from 'react'
import {useCallback, useState} from 'react'
import {
	Box, Button, Typography,
} from '@mui/material/'
import {
	useGetAnomalyFeedbackQuery, useCreateAnomalyFeedbackMutation, useUpdateAnomalyLimitedFieldsMutation,
} from '../../../../apis/anomalies'
import ScrollbarContainer from '../../../../shared/components/ScrollbarContainer'
import RadioQuestion from './RadioQuestion'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'
import AnomalyDetailsText from './AnomalyDetailsText'
import {getFormattedDate} from '../../../../util-functions'
import type {UserContextProps} from '../../../App/UserProvider'
import {useUserContext} from '../../../App/UserProvider'
import BasicLoader from '../../../../shared/components/loaders/BasicLoader'

type Props = {
    anomalyId: string,
	mainHeight: string,
	onSuccess: () => void,
};

enum SeverityLevel {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
}

const AnomalyFeedbackForm: FC<Props> = ({
	anomalyId, mainHeight, onSuccess,
}) => {
	const [createAnomalyFeedback] = useCreateAnomalyFeedbackMutation()
	const {
		data: anomalyFeedback, refetch, isFetching,
	} = useGetAnomalyFeedbackQuery({anomaly_id: anomalyId})
	const [updateAnomalyLimitedFields] = useUpdateAnomalyLimitedFieldsMutation()
	const {currentUser} = useUserContext() as UserContextProps
	const [formError, setFormError] = useState(false)
	const [t] = useCustomTranslation('anomalies')
	const [feedbackInputs, setFeedbackInputs] = useState<{
		validAnomaly: { value: string | null, details: string },
		notifyAnomaly: { value: string | null, details: string },
		severityLevel: { value: string | null, details: string },
		rootCauseKnown: { value: string | null, details: string },
		correctiveAction: { value: string | null, details: string },
	}>({
		validAnomaly: {value: null, details: ''},
		notifyAnomaly: {value: null, details: ''},
		severityLevel: {value: null, details: ''},
		rootCauseKnown: {value: null, details: ''},
		correctiveAction: {value: null, details: ''},
	})

	const feedback = Array.isArray(anomalyFeedback) && anomalyFeedback.length > 0 ? anomalyFeedback[0] : undefined

	const handleRadioChange = useCallback((section: keyof typeof feedbackInputs, value: string) => {
		setFeedbackInputs((prev) => ({
			...prev,
			[section]: {...prev[section], value},
		}))
	}, [])

	const handleDetailsChange = useCallback((section: keyof typeof feedbackInputs, value: string) => {
		setFeedbackInputs((prev) => ({
			...prev,
			[section]: {...prev[section], details: value},
		}))
	}, [])

	const handleSubmit = ():void => {
		if (feedback) return // Disable submit if feedback already exists

		const allQuestionsAnswered = (): boolean => {
			if (feedbackInputs.validAnomaly.value !== 'Yes') return true

			return (
				feedbackInputs.notifyAnomaly.value !== null
				&& feedbackInputs.severityLevel.value !== null
				&& feedbackInputs.rootCauseKnown.value !== null
				&& feedbackInputs.correctiveAction.value !== null
			)
		}

		if (!allQuestionsAnswered()) {
			setFormError(true)
			return
		}

		setFormError(false)

		const payload = {
			anomaly: anomalyId,
			is_valid_anomaly:
				feedbackInputs.validAnomaly.value === 'Yes',
			is_valid_anomaly_details: feedbackInputs.validAnomaly.details || null,
			should_notify_again:
				feedbackInputs.notifyAnomaly.value === 'Yes' ? true : feedbackInputs.notifyAnomaly.value === 'No' ? false : null,
			should_notify_again_details: feedbackInputs.notifyAnomaly.details || null,
			severity_level: feedbackInputs.severityLevel.value ? feedbackInputs.severityLevel.value.toLowerCase() : null,
			is_root_cause_known:
				feedbackInputs.rootCauseKnown.value === 'Yes' ? true : feedbackInputs.rootCauseKnown.value === 'No' ? false : null,
			root_cause_details: feedbackInputs.rootCauseKnown.details || null,
			corrective_action_performed:
				feedbackInputs.correctiveAction.value === 'Yes' ? true : feedbackInputs.correctiveAction.value === 'No' ? false : null,
			corrective_action_details: feedbackInputs.correctiveAction.details || null,
		}

		createAnomalyFeedback(payload).unwrap()
			.then(() => {
				updateAnomalyLimitedFields({
					id: anomalyId,
					status: 'CLASSIFIED',
				})
				onSuccess()
				refetch()
			})
	}

	return (
		<ScrollbarContainer height={mainHeight}>
			<Box data-testid="anomaly-feedback-form">
				{
					!isFetching ? (
						<Box
							sx={{px: 2.5, py: 2}}
						>
							{anomalyFeedback && anomalyFeedback.length > 0 ? (
								<Box
									sx={{marginBottom: 2}}
								>
									<AnomalyDetailsText
										detail={`${t('anomaly_feedback_chat.feedback_submitted')}:`}
										value={`${getFormattedDate(anomalyFeedback[0].submitted_at, currentUser, {
											showDate: true, showYear: true, showTime: true,
										})}`}
									/>
									<AnomalyDetailsText
										detail={`${t('anomaly_feedback_chat.submitted_by')}:`}
										value={`${anomalyFeedback[0].submitted_by.first_name} ${anomalyFeedback[0].submitted_by.last_name}`}
									/>
								</Box>
							) : null}
							<Typography
								variant="h4"
								component="h3"
								sx={{mb: 1}}
							>
								{t('anomaly_feedback_chat.feedback')}
								:
							</Typography>
							<RadioQuestion
								question={t('anomaly_feedback_chat.is_that_a_valid_anomaly')}
								options={[
									{label: t('anomaly_feedback_chat.yes'), value: 'Yes'},
									{label: t('anomaly_feedback_chat.no'), value: 'No'},
								]}
								value={feedback ? (feedback.is_valid_anomaly ? 'Yes' : 'No') : feedbackInputs.validAnomaly.value || ''}
								details={feedback ? feedback.is_valid_anomaly_details || '' : feedbackInputs.validAnomaly.details}
								triggerQuestionOption="No"
								onChange={(value) => handleRadioChange('validAnomaly', value)}
								onDetailsChange={(details) => handleDetailsChange('validAnomaly', details)}
								error={formError}
								disabled={Boolean(feedback)}
							/>
							{feedbackInputs.validAnomaly.value === 'Yes' || (feedback && feedback.is_valid_anomaly) ? (
								<>
									<RadioQuestion
										question={t('anomaly_feedback_chat.should_notify_again')}
										options={[
											{label: t('anomaly_feedback_chat.yes'), value: 'Yes'},
											{label: t('anomaly_feedback_chat.no'), value: 'No'},
										]}
										value={feedback ? (feedback.should_notify_again ? 'Yes' : 'No') : feedbackInputs.notifyAnomaly.value || ''}
										details={feedback ? feedback.should_notify_again_details || '' : feedbackInputs.notifyAnomaly.details}
										triggerQuestionOption="No"
										onChange={(value) => handleRadioChange('notifyAnomaly', value)}
										onDetailsChange={(details) => handleDetailsChange('notifyAnomaly', details)}
										error={formError}
										disabled={Boolean(feedback)}
									/>
									<RadioQuestion
										question={t('anomaly_feedback_chat.what_is_severity_level')}
										options={Object.values(SeverityLevel).map((level) => ({
											label: t(`anomaly_feedback_chat.${level.toLowerCase()}`),
											value: level,
										}))}
										value={feedback ? feedback.severity_level.charAt(0).toUpperCase() + feedback.severity_level.slice(1) : feedbackInputs.severityLevel.value || ''}
										details={feedbackInputs.severityLevel.details}
										onChange={(value) => handleRadioChange('severityLevel', value)}
										onDetailsChange={(details) => handleDetailsChange('severityLevel', details)}
										error={formError}
										disabled={Boolean(feedback)}
									/>
									<RadioQuestion
										question={t('anomaly_feedback_chat.is_root_cause_known')}
										options={[
											{label: t('anomaly_feedback_chat.yes'), value: 'Yes'},
											{label: t('anomaly_feedback_chat.no'), value: 'No'},
										]}
										value={feedback ? (feedback.is_root_cause_known ? 'Yes' : 'No') : feedbackInputs.rootCauseKnown.value || ''}
										details={feedback ? feedback.root_cause_details || '' : feedbackInputs.rootCauseKnown.details}
										triggerQuestionOption="Yes"
										onChange={(value) => handleRadioChange('rootCauseKnown', value)}
										onDetailsChange={(details) => handleDetailsChange('rootCauseKnown', details)}
										error={formError}
										disabled={Boolean(feedback)}
									/>
									<RadioQuestion
										question={t('anomaly_feedback_chat.is_there_a_corrective_action')}
										options={[
											{label: t('anomaly_feedback_chat.yes'), value: 'Yes'},
											{label: t('anomaly_feedback_chat.no'), value: 'No'},
										]}
										value={feedback ? (feedback.corrective_action_performed ? 'Yes' : 'No') : feedbackInputs.correctiveAction.value || ''}
										details={feedback ? feedback.corrective_action_details || '' : feedbackInputs.correctiveAction.details}
										triggerQuestionOption={['Yes', 'No']}
										onChange={(value) => handleRadioChange('correctiveAction', value)}
										onDetailsChange={(details) => handleDetailsChange('correctiveAction', details)}
										error={formError}
										disabled={Boolean(feedback)}
									/>
								</>
							) : null}
							<Box sx={{
								mt: 2, display: 'flex', justifyContent: 'flex-end',
							}}
							>
								<Button
									variant="contained"
									color="primary"
									onClick={handleSubmit}
									disabled={Boolean(feedback)}
								>
									{t('anomaly_feedback_chat.submit_feedback')}
								</Button>
							</Box>
						</Box>
					) : (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '80%',
							}}
						>
							{' '}
							<BasicLoader />
						</Box>
					)
				}
			</Box>
		</ScrollbarContainer>
	)
}

export default AnomalyFeedbackForm
