import {useCustomTranslation} from './useCustomTranslation.ts'

export const useEquipmentTypes = (): {equipmentTypes: {name: string, value: string}[], getEquipmentType: (value: string) => string} => {
	const [t] = useCustomTranslation('common')
	const equipmentTypes = [
		{
			name: t('equipment_types.tank'),
			value: 'tank',
		},
		{
			name: t('equipment_types.separator'),
			value: 'separator',
		},
		{
			name: t('equipment_types.heat_exchanger'),
			value: 'heat_exchanger',
		},
		{
			name: t('equipment_types.mixer'),
			value: 'mixer',
		},
		{
			name: t('production_timeline.highlight_program.none'),
			value: '',
		},
	]

	const getEquipmentType = (type: string): string => {
		const newEquipment = equipmentTypes.find((equipmentType) => equipmentType.value === type)
		return newEquipment?.name || equipmentTypes[4].name
	}

	return {equipmentTypes, getEquipmentType}
}
