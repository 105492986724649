import type {ReactNode, FC} from 'react'
import {useSelector} from 'react-redux'
import {ThemeProvider} from '@mui/material/styles'
import {lightTheme} from './lightTheme'
import {darkTheme} from './darkTheme'
import type {RootState} from '../store'

const MBAIThemeProvider: FC<{ children: ReactNode }> = ({children}): JSX.Element => {
	const theme = useSelector((state: RootState) => state.user.preferredTheme)
	const currentTheme = theme === 'dark' ? darkTheme : lightTheme
	return (
		<ThemeProvider
			theme={currentTheme}
		>
			{children}
		</ThemeProvider>
	)
}

export default MBAIThemeProvider
