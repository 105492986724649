import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {GridRowSelectionModel} from '@mui/x-data-grid'
import {getUniqueValuesFromArray, getUrlParams} from '../../util-functions'
import type {
	ViewsSignalInterface, OverlayedViewSignalInterface, SignalInterface,
	SliceStateProps,
} from '../../shared/interfaces/signal'

type InitialStateProps = SliceStateProps

const initialState: InitialStateProps = {
	all: [],
	active: [],
	overlayed: [],
	activeSignalsViews: [],
	order: [],
	url: '',
	activeSignalsIds: [],
}

export const signals = createSlice({
	name: 'signals',
	initialState,
	reducers: {
		setAllSignals: (state, action: PayloadAction<SignalInterface[]>) => ({
			...state,
			all: action.payload,
		}),
		setGlobalActiveSignals: (state, action: PayloadAction<OverlayedViewSignalInterface[] | SignalInterface[] | ViewsSignalInterface[] | GridRowSelectionModel | null>) => {
			if (action.payload) {
				const activeSignals = action.payload.map((item) => {
					if (!item) return null
					let signal
					if (typeof item === 'string') {
						signal = state.all.find((i: SignalInterface) => i.id === item)
					} else if ((item as SignalInterface).id) {
						signal = state.all.find((i: SignalInterface) => i.id === (item as SignalInterface).id)
					} else if ((item as ViewsSignalInterface).signal) {
						// Handles selected signals from views
						signal = state.all.find((i: SignalInterface) => i.id === (item as ViewsSignalInterface).signal)
					}
					return signal
				})
					.filter((item) => item !== undefined && item !== null)
				if (activeSignals) {
					const signalsIds = activeSignals.map((item) => (item as SignalInterface)?.id)
					const signalsURL = getUrlParams(activeSignals.map((item) => (item as SignalInterface)?.id), 'signal')
					return {
						...state,
						active: getUniqueValuesFromArray(activeSignals) as SignalInterface[],
						url: activeSignals[0] ? signalsURL : '',
						overlayed: activeSignals.map((item) => ({signal: (item as SignalInterface)?.id})),
						activeSignalsIds: signalsIds,
					}
				}
			}
			return {
				...state,
				active: action.payload as SignalInterface[],
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	setAllSignals,
	setGlobalActiveSignals,
} = signals.actions
export default signals.reducer
