import type {FC, ReactElement} from 'react'
import {Box, Skeleton} from '@mui/material'
import NivoGraphLoader from '../NivoGraph'

const LineSignalsLoader: FC = (): ReactElement => {
	const height = 'calc(100vh - 220px)'
	return (
		<Box>
			<Box
				data-testid="mbai-main-machine-signals-page-loader"
				sx={
					{
						width: '100%',
						height: '100vh',
						px: 2,
						pt: 2,
					}
				}
			>
				<Box>
					<Skeleton
						variant="rounded"
						sx={
							{
								width: '100%',
								height: '101px',
							}
						}
					/>
				</Box>
				<Box
					sx={
						{
							pt: 2,
							display: 'flex',
							justifyContent: 'space-between',
						}
					}
				>
					<Skeleton
						variant="rounded"
						sx={
							{
								width: '240px',
								height,
							}
						}
					/>
					<Box
						sx={
							{
								display: 'flex',
								flexGrow: 1,
								mx: 2,
							}
						}
					>
						<Box sx={
							{width: '100%'}
						}
						>
							<NivoGraphLoader />
						</Box>
					</Box>
					<Skeleton
						variant="rounded"
						sx={
							{
								width: '344px',
								height,
							}
						}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export default LineSignalsLoader
