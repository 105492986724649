import {
	useEffect, useRef, useCallback, type RefCallback,
} from 'react'

export const useResizeObserver = (onResize: (entry: ResizeObserverEntry) => void): RefCallback<Element> => {
	const observerRef = useRef<ResizeObserver | null>(null)
	const elementRef = useRef<Element | null>(null)

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				onResize(entry)
			})
		})

		observerRef.current = observer

		if (elementRef.current) {
			observer.observe(elementRef.current)
		}

		return () => {
			observer.disconnect()
		}
	}, [onResize])

	return useCallback((node: Element | null) => {
		if (elementRef.current) {
			observerRef.current?.unobserve(elementRef.current)
		}

		if (node) {
			observerRef.current?.observe(node)
		}

		elementRef.current = node
	}, [])
}
