import type {FC, ReactElement} from 'react'
import {Box, Skeleton} from '@mui/material'
import GlobalIconLoader from '../GlobalIconLoader'

const DashTemplateLoader: FC = (): ReactElement => (
	<Box
		data-testid="mbai-dash-template-loader"
		sx={
			{
				width: '100%',
				height: '100vh',
			}
		}
	>
		<Box
			sx={
				{display: 'flex'}
			}
		>
			<Box
				sx={
					{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						pt: 2,
						px: 2,
						height: '100vh',
						backgroundColor: 'background.default',
						borderRight: 1,
						borderColor: 'divider',
					}
				}
			>
				<Box
					sx={
						{
							display: 'flex',
							flexDirection: 'column',
						}
					}
				>
					{
						[...Array(4)].map((item) => (
							<GlobalIconLoader
								key={Math.random()}
								item={item}
								horizontal={false}
							/>
						))
					}
				</Box>
				<Box
					sx={
						{
							display: 'flex',
							flexDirection: 'column',
						}
					}
				>
					{
						[...Array(2)].map((item) => (
							<GlobalIconLoader
								key={Math.random()}
								item={item}
								horizontal={false}
							/>
						))
					}
				</Box>
			</Box>
			<Box
				sx={
					{width: '100%'}
				}
			>
				<Box
					sx={
						{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							flexGrow: 1,
							py: 2,
							px: 3,
							borderBottom: 1,
							borderColor: 'divider',
						}
					}
				>
					<Box
						sx={
							{
								display: 'flex',
								alignItems: 'center',
								width: '500px',
							}
						}
					>
						<Skeleton
							variant="rounded"
							sx={
								{
									height: '32px',
									width: '32px',
								}
							}
						/>
						<Skeleton
							sx={
								{
									width: '100%',
									maxWidth: '200px',
									ml: 2.5,
								}
							}
						/>
					</Box>
					<Box
						sx={
							{
								display: 'flex',
								alignItems: 'center',
							}
						}
					>
						{
							[...Array(5)].map((item) => (
								<GlobalIconLoader
									key={Math.random()}
									item={item}
								/>
							))
						}
					</Box>
				</Box>
				<Box
					sx={
						{
							pt: 2,
							px: 3.5,
							width: '100%',
						}
					}
				>
					<Skeleton
						variant="rounded"
						sx={
							{
								width: '100%',
								height: 'calc(100vh - 134px)',
								mt: 2,
							}
						}
					/>
				</Box>
			</Box>
		</Box>
	</Box>
)

export default DashTemplateLoader
