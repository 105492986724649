import type {FC, ReactElement} from 'react'
import {
	Box, IconButton, Card,
} from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import {ICON_BUTTON_SIZES} from '../../../../shared/constants/IconButtonSizes.ts'

interface ChatMessageActionsProps {
	hovered: boolean,
	isCurrentUser: boolean,
	updating?: boolean,
	onCommentDelete?: () => void,
	onCommentUpdateStart?: () => void,
	onCommentUpdateStop?: () => void,
	onCommentUpdate?: () => void,
}

interface ChatMessageActionsIconProps {
	icon: ReactElement,
	onClickAction?: () => void,
}

const ChatMessageActionsIcon: FC<ChatMessageActionsIconProps> = ({onClickAction, icon}): ReactElement => (
	<IconButton
		onClick={onClickAction}
	>
		{icon}
	</IconButton>
)

const ChatMessageActions: FC<ChatMessageActionsProps> = ({
	hovered,
	isCurrentUser,
	updating = false,
	onCommentDelete,
	onCommentUpdateStart,
	onCommentUpdateStop,
	onCommentUpdate,
}): ReactElement | null => {
	// Basic Variables

	const iconStyles = {
		height: `${ICON_BUTTON_SIZES.medium}px`,
		width: `${ICON_BUTTON_SIZES.medium}px`,
	}

	if (updating) {
		return (
			<Box
				sx={{
					display: 'flex', mr: 0.5, mb: 0.5,
				}}
				data-testid="chat-message-actions"
			>
				<Box sx={{mr: 0.5}}>
					<ChatMessageActionsIcon
						onClickAction={onCommentUpdateStop}
						icon={(
							<CloseOutlinedIcon
								sx={iconStyles}
							/>
						)}
					/>
				</Box>
				<ChatMessageActionsIcon
					onClickAction={onCommentUpdate}
					icon={(
						<CheckOutlinedIcon
							sx={iconStyles}
						/>
					)}
				/>
			</Box>
		)
	} if (hovered && isCurrentUser) {
		return (
			<Card
				elevation={5}
				sx={{
					position: 'absolute',
					top: '-40px',
					right: 0,
					backgroundColor: 'background.paper',
					padding: '4px',
				}}
			>
				<Box
					sx={{display: 'flex'}}
				>
					<Box sx={{mr: 0.5}}>
						<ChatMessageActionsIcon
							onClickAction={onCommentUpdateStart}
							icon={(
								<EditOutlinedIcon
									sx={iconStyles}
								/>
							)}
						/>
					</Box>
					<ChatMessageActionsIcon
						onClickAction={onCommentDelete}
						icon={(
							<DeleteOutlineOutlinedIcon
								sx={iconStyles}
							/>
						)}
					/>
				</Box>
			</Card>
		)
	}
	return null
}

export default ChatMessageActions
