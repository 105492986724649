import type {
	FC, ReactElement, ReactNode,
} from 'react'
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import {useNavigate} from 'react-router-dom'
import {
	isNewAuthSystemEnabled,
	useFetchAccessTokenMutation,
	useRevokeTokenMutation,
} from '../../apis/auth'
import type {AuthResponseInterface} from '../../shared/interfaces/auth'

const ONE_MINUTE = 60000

interface UserProviderProps {
	children?: ReactNode,
}

export interface AuthContextProps {
	userIsLoggedIn: boolean,
	loginUser: (creds: AuthResponseInterface | string) => void,
	logoutUser: () => void,
	refreshToken: () => Promise<boolean>,
}

const AuthContext = createContext<AuthContextProps | null>(null)

const useAuthContext = (): AuthContextProps | null => useContext(AuthContext)

const AuthProvider: FC<UserProviderProps> = ({children}): ReactElement => {
	const navigate = useNavigate()
	const [userIsLoggedIn, setUserIsLoggedIn] = useState<boolean>(!!localStorage.getItem('token'))
	const [revokeToken] = useRevokeTokenMutation()
	const [fetchAccessToken] = useFetchAccessTokenMutation()

	const logoutUser = useCallback(async (): Promise<void> => {
		try {
			const token = localStorage.getItem('token')
			if (token && isNewAuthSystemEnabled) {
				await revokeToken(token).unwrap()
			}
		} catch (error) {
			console.error('Failed to revoke token:', error)
		} finally {
			localStorage.clear()
			setUserIsLoggedIn(false)
			navigate('/login')
		}
	}, [revokeToken, navigate])

	const refreshToken = useCallback(async (): Promise<boolean> => {
		const refresh_token = localStorage.getItem('refresh_token')
		if (!refresh_token) {
			await logoutUser()
			return false
		}
		try {
			const response = await fetchAccessToken({refresh_token}).unwrap()
			if (response.access_token && response.expires_in) {
				localStorage.setItem('token', response.access_token)
				localStorage.setItem('refresh_token', response.refresh_token)
				localStorage.setItem('expires_in', response.expires_in.toString())
				return true
			}
		} catch (error) {
			console.error('Failed to refresh token:', error)
		}
		await logoutUser()
		return false
	}, [fetchAccessToken, logoutUser])

	useEffect(() => {
		const scheduleTokenRefresh = (): (() => void) => {
			const expiresIn = Number(localStorage.getItem('expires_in'))
			if (!expiresIn) return () => {}

			const refreshTime = expiresIn * 1000 - ONE_MINUTE // Refresh 1 minute before expiry
			const timerId = setTimeout(async () => {
				const success = await refreshToken()
				if (!success) await logoutUser()
			}, refreshTime)

			return () => clearTimeout(timerId)
		}

		const cancelTokenRefresh = scheduleTokenRefresh()
		return () => cancelTokenRefresh()
	}, [refreshToken, logoutUser])

	const loginUser = useCallback((creds: AuthResponseInterface | string): void => {
		const token = typeof creds === 'string' ? creds : creds.token
		const refresh_token = typeof creds !== 'string' ? creds.refresh_token : null
		const expires_in = typeof creds !== 'string' ? creds.expires_in : null

		localStorage.setItem('token', token)
		if (refresh_token) localStorage.setItem('refresh_token', refresh_token)
		if (expires_in) localStorage.setItem('expires_in', expires_in.toString())

		setUserIsLoggedIn(true)
		navigate('/')
	}, [navigate])

	const contextValue = useMemo(
		() => ({
			userIsLoggedIn,
			loginUser,
			logoutUser,
			refreshToken,
		}),
		[userIsLoggedIn, loginUser, logoutUser, refreshToken],
	)

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export {AuthProvider, useAuthContext}
