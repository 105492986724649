import type {
	BaseSyntheticEvent, FC, ReactElement,
} from 'react'
import {
	useState, StrictMode, useEffect,
} from 'react'
import {
	Popover,
	Button,
	Paper,
	MenuList,
	MenuItem,
	ListItemIcon,
	Divider,
	Tooltip,
} from '@mui/material'
import {useSelector} from 'react-redux'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation'
import type {RootState} from '../../App/store'
import LanguageFlag from './LanguageFlag'
import type {AvailableLanguageInterface} from '../../../shared/constants/preferences/languages'
import {availableLanguages} from '../../../shared/constants/preferences/languages'

type Props = {
	preferredLanguage?: string,
}

const AppbarLanguage: FC<Props> = ({preferredLanguage}): ReactElement => {
	const defaultLanguage = useSelector((state: RootState) => state.user.preferredLanguage)
	const [t, i18n] = useCustomTranslation('common')
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const [language, setLanguage] = useState<string>('')
	const [languageObject, setLanguageObject] = useState<AvailableLanguageInterface>(availableLanguages[0])

	const handleClick = (event: BaseSyntheticEvent): void => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (): void => {
		setAnchorEl(null)
	}

	useEffect(() => {
		if (preferredLanguage) {
			setLanguage(preferredLanguage as string)
		} else {
			setLanguage(defaultLanguage)
		}
	}, [preferredLanguage, defaultLanguage])

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const getLanguageObject = (language: string): AvailableLanguageInterface | undefined => availableLanguages.find((item) => item.id === language)
	// Adjusts the language based on the defaultLanguage
	useEffect(() => {
		setLanguage(defaultLanguage)
		setLanguageObject(getLanguageObject(defaultLanguage) as AvailableLanguageInterface)
	}, [defaultLanguage])

	useEffect(() => {
		i18n.changeLanguage(language)
			.then(() => {
				setLanguageObject(getLanguageObject(language) as AvailableLanguageInterface)
			})
	}, [language, i18n])

	const changeLanguage = (lng: string): void => {
		setLanguage(lng)
		handleClose()
	}

	return (
		<StrictMode>
			<Tooltip
				title={t('appbar.switch_language')}
				arrow
			>
				<Button
					id="mbai-main-appbar-language"
					data-testid="mbai-main-appbar-language"
					aria-describedby={id}
					onClick={handleClick}
					sx={
						{ml: 0.5}
					}
				>
					<LanguageFlag
						image={languageObject ? languageObject.image : ''}
						label={languageObject ? languageObject.label : ''}
						imgAltText={languageObject ? languageObject.id : ''}
					/>
				</Button>
			</Tooltip>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={
					{
						vertical: 'bottom',
						horizontal: 'left',
					}
				}
				sx={
					{mt: 2.5}
				}
			>
				<Paper sx={
					{width: 100, maxWidth: '100%'}
				}
				>
					<MenuList>
						{
							availableLanguages.map((language, index) => (
								<div key={language.id}>
									<MenuItem
										key={language.id}
										id={language.id}
										onClick={() => changeLanguage(language.id)}
										className={languageObject && language.id === languageObject.id ? 'Mui-selected' : ''}
									>
										<ListItemIcon>
											<LanguageFlag
												image={language.image}
												label={language.label}
												imgAltText={language.id}
											/>
										</ListItemIcon>
									</MenuItem>
									{
										index < 3 ? (
											<Divider component="li" />
										) : null
									}
								</div>
							))
						}
					</MenuList>
				</Paper>
			</Popover>
		</StrictMode>
	)
}

export default AppbarLanguage
