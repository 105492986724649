import {
	type FC, useState, memo, useCallback,
} from 'react'
import {
	Box, Typography, Card, CardContent,
} from '@mui/material/'
import ChatMessageActions from './ChatMessageActions'
import BasicMessageBox from '../../../../shared/components/base/BasicMessageBox.tsx'
import {useUpdateAnomalyDiscussionCommentMutation} from '../../../../apis/anomalies.ts'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation.ts'

type MessageProps = {
    comment: {
        id: string,
        comment_text: string,
        is_deleted: boolean,
        created_by: { id: number, first_name: string, last_name: string },
		anomaly: string,
    },
    isCurrentUser: boolean,
	dateCreated: string,
	lastItem: boolean,
	onChatMessageUpdate: () => void,
};

const ChatMessage: FC<MessageProps> = ({
	comment,
	isCurrentUser,
	dateCreated,
	lastItem,
	onChatMessageUpdate,
}) => {
	// States
	const [hovered, setHovered] = useState<boolean>(false)
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [message, setMessage] = useState<string>(comment.comment_text)
	const [t] = useCustomTranslation('anomalies')
	// RTK Queries
	const [updateAnomalyDiscussionComment] = useUpdateAnomalyDiscussionCommentMutation()

	// Functions
	const handleUpdateMessage = (message: string): void => {
		setMessage(message)
	}

	const handleCommentUpdateStart = (): void => {
		setIsEditing(true)
	}

	const handleCommentUpdateStop = (): void => {
		setIsEditing(false)
	}

	const handleCommentUpdate = useCallback((commentId: string, anomalyId: string, message: string): void => {
		if (message.trim() !== '') {
			updateAnomalyDiscussionComment({
				id: commentId,
				anomaly: anomalyId,
				comment_text: message,
			}).unwrap()
				.then(() => {
					onChatMessageUpdate()
				})
		}
		setIsEditing(false)
	}, [updateAnomalyDiscussionComment, onChatMessageUpdate])

	const handleDeleteComment = useCallback((commentId: string, anomalyId: string) => {
		updateAnomalyDiscussionComment({
			id: commentId,
			is_deleted: true,
			anomaly: anomalyId,
		}).unwrap()
			.then(() => {
				onChatMessageUpdate()
			})
	}, [updateAnomalyDiscussionComment, onChatMessageUpdate])

	return (
		<Box
			sx={{width: '100%'}}
			data-testid="chat-message"
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: isCurrentUser ? 'flex-end' : 'flex-start', // Align to right or left
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Typography
						variant="caption"
						sx={{color: 'text.disabled'}}
					>
						{`${comment.created_by.first_name} | ${dateCreated}`}
					</Typography>
				</Box>
				<Card
					variant="outlined"
					sx={{
						maxWidth: '80% !Important',
						mb: lastItem ? 0 : 1,
						overflow: 'visible',
						minWidth: isEditing ? '80%' : '',
					}}
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
				>
					{
						isEditing && isCurrentUser ? (
							<Box sx={{width: '100%', position: 'relative'}}>
								<BasicMessageBox
									onMessageUpdated={handleUpdateMessage}
									defaultValue={message}
									updating
								/>
								<Box
									sx={{
										position: 'absolute', bottom: 0, right: 0,
									}}
								>
									<ChatMessageActions
										hovered={hovered}
										isCurrentUser={isCurrentUser}
										onCommentUpdateStop={handleCommentUpdateStop}
										onCommentUpdate={() => handleCommentUpdate(comment.id, comment.anomaly, message)}
										updating
									/>
								</Box>
							</Box>
						) : (
							<CardContent
								sx={{
									paddingBottom: '20px !important',
									position: 'relative',
								}}
							>
								{
									comment.is_deleted ? (
										<Typography
											variant="body2"
											sx={{
												fontStyle: 'italic',
												color: 'text.disabled',
											}}
										>
											{t('anomaly_feedback_chat.this_message_has_been_deleted')}
										</Typography>
									) : (
										<>
											<ChatMessageActions
												hovered={hovered}
												isCurrentUser={isCurrentUser}
												onCommentDelete={() => handleDeleteComment(comment.id, comment.anomaly)}
												onCommentUpdateStart={handleCommentUpdateStart}
											/>
											<Typography
												variant="body2"
												sx={{mt: 0}}
											>
												{comment.comment_text}
											</Typography>
										</>
									)
								}
							</CardContent>
						)
					}
				</Card>
			</Box>
		</Box>
	)
}

export default memo(ChatMessage)
