import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

const initialState: {
    time: boolean,
    signalsOverlay: boolean,
} = {
	time: true,
	signalsOverlay: false,
}

export const signalsScreenModes = createSlice({
	name: 'signalsScreenModes',
	initialState,
	reducers: {
		setGlobalTimeMode: (state, action: PayloadAction<boolean>) => ({
			...state,
			time: action.payload,
		}),
		setGlobalSignalsOverlayMode: (state, action: PayloadAction<boolean>) => ({
			...state,
			signalsOverlay: action.payload,
		}),
	},
})

// Action creators are generated for each case reducer function
export const {setGlobalTimeMode, setGlobalSignalsOverlayMode} = signalsScreenModes.actions
export default signalsScreenModes.reducer
