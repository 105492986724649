import {useCustomTranslation} from '../useCustomTranslation'
import type {StepsInterface} from '../../interfaces/steps'

export const useLineOverviewSteps = (): StepsInterface[] => {
	const [t] = useCustomTranslation('tutorial')
	const machineOverviewSteps: StepsInterface[] = [
		{
			title: t('welcome_to_MontBlancAI'),
			intro: `${t('welcome_description')} 👋`,
		},
		{
			element: '#mbai-main-appbar .selectorAppbar',
			title: t('appbar.title'),
			intro: t('appbar.description'),
			position: 'right',
		},
		{
			element: '#mbai-main-appbar-language',
			title: t('appbar.language_title'),
			intro: t('appbar.language_description'),
			position: 'right',
		},
		{
			element: '#mbai-appbar-profile-button',
			title: t('appbar.profile_title'),
			intro: t('appbar.profile_description'),
			position: 'right',
		},
		{
			element: '#mbai-main-appbar-notifications',
			title: t('appbar.notifications_title'),
			intro: t('appbar.notifications_description'),
			position: 'right',
		},
		{
			element: '#mbai-appbar-theme-toggle',
			title: t('appbar.theme_title'),
			intro: t('appbar.theme_description'),
			position: 'right',
		},
		{
			element: '#mbai-main-layout-drawer-component',
			title: t('side_menu.title'),
			intro: t('side_menu.description'),
			position: 'right',
		},
		{
			element: '#mbai-main-line-overview-link',
			title: t('side_menu.line_overview_title'),
			intro: t('side_menu.line_overview_description'),
			position: 'right',
		},
		{
			element: '#mbai-main-batch-traceability-link',
			title: t('side_menu.batch_traceability_title'),
			intro: t('side_menu.batch_traceability_description'),
			position: 'right',
		},
		{
			element: '#mbai-main-settings-link',
			title: t('side_menu.settings_title'),
			intro: t('side_menu.settings_description'),
			position: 'right',
		},
	]
	return machineOverviewSteps
}
