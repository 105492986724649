import type {FC, ReactElement} from 'react'
import type {TooltipProps} from '@mui/material'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'
import BasicSelect from '../../../../shared/components/BasicSelect'

type TooltipPlacementOptions = TooltipProps['placement']

interface SelectComponentProps {
    label: string,
    isLoading: boolean,
    data: { id: string, name: string }[],
    value: string | undefined,
    onChange: (value: string) => void,
    inputProps: {
        id: string,
    },
    disabled?: boolean,
    noDataMessage: string,
    showNoneOption?: boolean,
	displayLabel?: boolean,
	tooltipPlacement?: TooltipPlacementOptions,
}

const SelectComponent: FC<SelectComponentProps> = (
	{
		label,
		isLoading,
		data,
		value,
		onChange,
		inputProps,
		disabled,
		noDataMessage,
		showNoneOption = true,
		displayLabel = true,
		tooltipPlacement = 'top-start',
	},
): ReactElement => {
	const [t] = useCustomTranslation('batch_traceability')
	return (
		<BasicSelect
			label={label}
			list={data || []}
			value={value || 'none'}
			onBasicSelectChange={(value: string | string[]) => onChange(value as string)}
			inputProps={inputProps}
			disabled={disabled || isLoading}
			nullOption={showNoneOption}
			nullLabel={t('none') || noDataMessage}
			loading={isLoading}
			data-testid="mbai-batch-tracability-select-main-component"
			displayLabel={displayLabel}
			tooltipPlacement={tooltipPlacement}
		/>
	)
}

export default SelectComponent
