import type {TFunction} from 'react-i18next'
import type {OverridableStringUnion} from '@mui/types'
import type {ChipPropsColorOverrides} from '@mui/material'

export const getChipLabel = (notificationSeverity: string, t: TFunction): string => {
	switch (notificationSeverity) {
		case 'warning':
			return t('appbar.notifications.warning')
		case 'critical':
			return t('appbar.notifications.critical')
		default:
			return 'Unexpected'
	}
}

export const getChipColor = (notificationSeverity: string): OverridableStringUnion<'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning', ChipPropsColorOverrides> => {
	switch (notificationSeverity) {
		case 'warning':
			return 'warning'
		case 'critical':
			return 'error'
		default:
			return 'error'
	}
}
