import {
	Avatar, Box, Divider,
	Toolbar,
} from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import {useTheme} from '@mui/material/styles'
import type {FC, ReactElement} from 'react'
import {NavLink} from 'react-router-dom'
import PageTitle from '../../../shared/components/PageTitle'
import {useLogo} from '../../../shared/hooks/useLogo'
import UpdateTutorial from '../../../shared/tutorial/steps/UpdateTutorial'
import AppbarLanguage from './AppbarLanguage'
import AppbarNotifications from './AppbarNotifications'
import AppbarProfile from './AppbarProfile'
import AppbarSearch from './AppbarSearch'
import HelpItem from './HelpItem'
import ToggleTheme from './ToggleTheme'

interface AppbarProps {
    open: boolean,
}

const Appbar: FC<AppbarProps> = ({open}): ReactElement => {
	const theme = useTheme()
	const {mainLogo} = useLogo()

	return (
		<MuiAppBar
			position="sticky"
			elevation={0}
			sx={
				{
					backgroundColor: 'background.default',
					zIndex: theme.zIndex.drawer + 1,
					borderBottom: 1,
					borderColor: 'divider',
				}
			}
			data-testid="mbai-main-appbar"
			id="mbai-main-appbar"
		>
			<Toolbar>
				<Box
					sx={
						{
							flexGrow: 1,
							display: 'flex',
							alignItems: 'center',
						}
					}
					className="selectorAppbar"
				>
					<NavLink
						className=""
						to="/"
						style={
							{display: !open ? 'block' : 'none'}
						}
					>
						<Avatar
							src={mainLogo}
							sx={
								{
									width: '30px',
									height: '30px',
									mr: 2,
								}
							}
						/>
					</NavLink>
					<PageTitle />
					<Box sx={
						{
							mx: 'auto',
							flexGrow: 1,
							paddingLeft: 10,
						}
					}
					>
						<AppbarSearch />
					</Box>
					<Box
						sx={
							{
								flexGrow: 1,
								display: {
									sm: 'flex',
									xs: 'none',
								},
								justifyContent: 'flex-end',
							}
						}
					>
						<HelpItem />
						<Divider
							orientation="vertical"
							flexItem
							sx={
								{
									backgroundColor: 'text.secondary',
									my: 1,
								}
							}
						/>
						<UpdateTutorial />
						<ToggleTheme />
						<AppbarNotifications />
						<Box
							sx={
								{mr: 1}
							}
						>
							<AppbarLanguage />
						</Box>
						<Divider
							orientation="vertical"
							flexItem
							sx={
								{
									backgroundColor: 'text.secondary',
									my: 1,
								}
							}
						/>
						<AppbarProfile />
					</Box>
				</Box>
			</Toolbar>
		</MuiAppBar>
	)
}

export default Appbar
