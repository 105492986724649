import type {FC} from 'react'
import {
	Box, CardContent, CardHeader, ListItem, Typography,
} from '@mui/material/'
import BasicCard from '../../../../shared/components/BasicCard'
import type {D3DailyProductionBarTooltipProps} from '../../../../shared/interfaces/d3DailyProductionBarTooltipProps'
import {classificationTranslationMapping} from '../../../../util-functions'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'

const D3DailyProductionBarTooltip:FC<D3DailyProductionBarTooltipProps> = ({
	open, color, seriesKey, value, percentage, unusedCapacity,
}) => {
	const [t] = useCustomTranslation('common')

	return (
		<BasicCard
			sx={
				{
					display: open ? 'block' : 'none',
					position: 'fixed',
					top: '10px', // Set the tooltip in the top-left corner
					right: '10px',
					zIndex: 10000,
					textAlign: 'left',
					color,
					borderColor: 'primary.main',
					border: 3,
				}
			}
			data-testid="mbai-d3daily-production-bar-tooltip"
		>
			<CardHeader
				title={classificationTranslationMapping(seriesKey, t)}
			/>
			<CardContent
				sx={
					{pb: '12px !important'}
				}
			>
				<Box
					sx={
						{display: 'flex', flexDirection: 'column'}
					}
				>
					<Typography
						variant="caption"
						component="span"
						sx={
							{
								color: 'text.disabled',
								mr: 0.5,
							}
						}
					>
						{`${t('duration')}: ${value.toFixed(1)}`}
					</Typography>
					<Typography
						variant="caption"
						component="span"
						sx={
							{
								color: 'text.disabled',
								mr: 0.5,
							}
						}
					>
						{`${t('percentage')}: ${percentage}%`}
					</Typography>
					{unusedCapacity ? (
						<Typography
							variant="caption"
							component="span"
							sx={{
								color: 'text.disabled',
								mr: 0.5,
							}}
						>
							{`${t('unused_capacity')}:`}
							{Object.entries(unusedCapacity).map(([programName, value]) => (
								<ListItem
									key={programName}
									style={{paddingTop: 0, paddingBottom: 2}}
								>
									<Typography
										variant="caption"
										component="span"
										sx={{color: 'text.disabled', mr: 0.5}}
									>
										{`${programName}: ${value}`}
									</Typography>
								</ListItem>
							))}
						</Typography>
					) : null}
				</Box>
			</CardContent>
		</BasicCard>
	)
}

export default D3DailyProductionBarTooltip
