import type {ReactElement, FC} from 'react'
import {Provider} from 'react-redux'
import 'bootstrap/dist/css/bootstrap.css'
import {I18nextProvider} from 'react-i18next'
import i18n from 'i18next'
import {CssBaseline} from '@mui/material'
import {BrowserRouter} from 'react-router-dom'
import {setupStore} from './store'
import {config as i18nextConfig} from '../../translations'
import MBAIThemeProvider from './themes/MBAIThemeProvider'
import MBAISnackBarProvider from './MBAISnackBarProvider'
import Router from './Router'
import 'intro.js/introjs.css'
import '../../index.css'
import {UserProvider} from './UserProvider'
import {AuthProvider} from './AuthProvider'
import {WebSocketNotificationsProvider} from './WebSocketNotificationsProvider'

const store = setupStore({})
i18n.init(i18nextConfig)

const App: FC = (): ReactElement => (
	<Provider store={store}>
		<MBAISnackBarProvider>
			<MBAIThemeProvider>
				<CssBaseline />
				<BrowserRouter>
					<I18nextProvider i18n={i18n}>
						<AuthProvider>
							<UserProvider>
								<WebSocketNotificationsProvider>
									<Router />
								</WebSocketNotificationsProvider>
							</UserProvider>
						</AuthProvider>
					</I18nextProvider>
				</BrowserRouter>
			</MBAIThemeProvider>
		</MBAISnackBarProvider>
	</Provider>
)

export default App
