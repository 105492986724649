import type {FC, ReactElement} from 'react'
import {StrictMode, useState} from 'react'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material'

const BookAMeeting: FC = (): ReactElement => {
	const bookAMeeting = 'Book A Meeting'
	const [open, setOpen] = useState<boolean>(false)

	const handleOpen = (): void => {
		setOpen(true)
	}
	const handleClose = (): void => {
		setOpen(false)
	}

	return (
		<StrictMode>
			<Button
				variant="contained"
				onClick={handleOpen}
				sx={
					{
						position: 'fixed',
						bottom: '15px',
						right: '15px',
					}
				}
				data-testid="mbai-main-book-a-meeting-button"
			>
				{bookAMeeting}
			</Button>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				data-testid="mbai-main-book-a-meeting-popover"
			>
				<DialogTitle id="customized-dialog-title">
					{bookAMeeting}
				</DialogTitle>
				<DialogContent
					dividers
					sx={
						{p: 0}
					}
				>
					<iframe
						src="https://outlook.office365.com/owa/calendar/MontBlancAI1@montblancai.com/bookings/"
						width="600px"
						height="100%"
						scrolling="yes"
						style={
							{
								border: 'none',
								minHeight: '600px',
							}
						}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						onClick={handleClose}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</StrictMode>
	)
}

export default BookAMeeting
