import type {FC} from 'react'
import {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import {useUsageEventMutation} from '../../../apis/usage.ts'

const GlobalUsageTracker: FC = (): null => {
	const minDuration = 1000 // 1 seconds minimum duration to send events
	const inactivityThreshold = 30000 // 30 seconds of inactivity considered inactive

	const location = useLocation()
	const [sendUsageEvent] = useUsageEventMutation()
	const startTimeRef = useRef<number | null>(null)
	const lastActivityRef = useRef<number | null>(null) // Tracks last activity time
	const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null) // Timer for inactivity

	useEffect(() => {
		// Capture the timestamp when the page is loaded
		startTimeRef.current = Date.now()
		lastActivityRef.current = Date.now()

		const pageViewTimeout = setTimeout(() : void => {
			sendUsageEvent({
				event_name: 'pm.core.usage.page_view',
				properties: {
					host: window.location.hostname,
					page: location.pathname,
					search_params: location.search,
					timestamp: new Date().toISOString(),
				},
			})
		}, minDuration)

		// Activity handler to reset inactivity timer
		const resetInactivityTimer = () : void => {
			lastActivityRef.current = Date.now()
			if (inactivityTimerRef.current) {
				clearTimeout(inactivityTimerRef.current)
			}
			inactivityTimerRef.current = setTimeout(() : void => {
				// When this timer runs, we consider the user inactive
				lastActivityRef.current = null // Mark inactivity
			}, inactivityThreshold)
		}

		// Register user activity events
		window.addEventListener('mousemove', resetInactivityTimer)
		window.addEventListener('keydown', resetInactivityTimer)
		window.addEventListener('scroll', resetInactivityTimer)

		// Send the usage event to the backend on page exit
		return () => {
			const exitTimestamp = Date.now()

			// Clear the timeout to prevent sending page_view if the user leaves before minDuration
			clearTimeout(pageViewTimeout)
			if (inactivityTimerRef.current) {
				clearTimeout(inactivityTimerRef.current)
			}

			// Calculate active duration by checking if the user was inactive
			const lastActiveTimestamp = lastActivityRef.current || exitTimestamp
			const activeDuration = lastActiveTimestamp - (startTimeRef.current || lastActiveTimestamp)

			// Only send the page_exit event if the active duration is >= minDuration
			if (activeDuration >= minDuration) {
				sendUsageEvent({
					event_name: 'pm.core.usage.page_exit',
					properties: {
						host: window.location.hostname,
						page: location.pathname,
						search_params: location.search,
						timestamp: new Date().toISOString(),
						duration: activeDuration / 1000, // Duration in seconds
					},
				})
			}

			// Cleanup event listeners on unmount
			window.removeEventListener('mousemove', resetInactivityTimer)
			window.removeEventListener('keydown', resetInactivityTimer)
			window.removeEventListener('scroll', resetInactivityTimer)
		}
	}, [location.pathname, location.search, sendUsageEvent])
	return null // No UI, it's purely for tracking
}

export default GlobalUsageTracker
