import type {FC, ReactNode} from 'react'
import {
	useState, useEffect, useMemo,
} from 'react'
import {RowDataContext} from './rowDataContext'
import type {UserContextProps} from '../../../App/UserProvider'
import {useUserContext} from '../../../App/UserProvider'
import type {ConnectionEdgeApiInterface} from '../../../../shared/interfaces/edgeapi/connectionEgeApi'
import {useGetConnectionsByTenantQuery} from '../../../../apis/edgeAPI.ts'

type Props = {
    children: ReactNode,
};

const RowDataProvider: FC<Props> = ({children}) => {
	const [rowData, setRowData] = useState<ConnectionEdgeApiInterface[]>([])
	const {currentUser} = useUserContext() as UserContextProps
	const {data, refetch} = useGetConnectionsByTenantQuery(undefined, {skip: !currentUser})
	/* eslint-disable */
	useEffect(() => {
		if (data) {
			const fetchData = () => {
				setRowData(data)
			}

			if (currentUser?.userprofile?.object_id) {
				fetchData()
			}

			try {
				// WebSocket connection
				const webSocketUrl = `${import.meta.env.VITE_WS_BASE_URL || ''}/core/ws/connection-status-updates/${currentUser?.userprofile?.object_id}/`
				const webSocket = new WebSocket(webSocketUrl)


				webSocket.onmessage = (event) => {
					const webSocketData = JSON.parse(event.data)
					setRowData((currentRows) => currentRows.map((row) => (row.id === webSocketData.data.id ? {
						...row, ...webSocketData.data,
					} : row)))
				}

				return () => {
					webSocket.close()
				}
			} catch (error) {
				console.error('Error fetching data', error)
			}
		}
	}, [currentUser, data])
	/* eslint-enable */
	const providerValue = useMemo(() => ({
		rowData, setRowData, refetch,
	}), [rowData, setRowData, refetch])

	return (
		<RowDataContext.Provider value={providerValue}>
			{children}
		</RowDataContext.Provider>
	)
}

export default RowDataProvider
