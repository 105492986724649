import {Typography} from '@mui/material'
import type {FC, ReactElement} from 'react'

const FormTitle: FC<{label: string}> = ({label}): ReactElement => (
	<Typography
		variant="h1"
		data-testid="mbai-auth-form-title"
		sx={
			{
				color: 'text.primary',
				mb: 0,
				mt: 0,
			}
		}
	>
		{label}
	</Typography>
)

export default FormTitle
