import {createSlice} from '@reduxjs/toolkit'

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		token: localStorage.getItem('token'),
		isLoggedIn: !!localStorage.getItem('token'),
	},
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload
			state.isLoggedIn = true
			localStorage.setItem('token', action.payload)
		},
		logoutUserAction: (state) => {
			state.token = null
			state.isLoggedIn = false
			localStorage.removeItem('token')
		},
	},
})

export const {setToken, logoutUserAction} = authSlice.actions
export default authSlice.reducer
