import type {FC, ReactElement} from 'react'
import {Button, Tooltip} from '@mui/material'
import {NavLink} from 'react-router-dom'

interface ActionButtonProps {
    dataName?: string,
    dataCy: string,
    link?: string,
    title: string,
    icon: ReactElement,
    onClick?: () => void,
    target?: string,
    isDisabled?: boolean,
}

const ActionButton: FC<ActionButtonProps> = ({
	dataName,
	dataCy,
	link,
	title,
	icon,
	onClick,
	target,
	isDisabled = false,
}): ReactElement => (
	<Tooltip
		title={title}
		arrow
	>
		<Button
			component={link ? NavLink : 'button'}
			to={link || undefined}
			data-testid="mbai-main-action-button-component"
			data-name={dataName}
			data-cy={dataCy}
			variant="outlined"
			disabled={isDisabled}
			target={target}
			onClick={onClick}
			sx={{
				width: '100%',
				'&:hover': {color: 'primary.main'},
			}}
		>
			{icon}
		</Button>
	</Tooltip>
)

export default ActionButton
