import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const isNewAuthSystemEnabled = import.meta.env.VITE_APP_AUTH_SYSTEM === 'provider'

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${import.meta.env.VITE_AUTH_PROVIDER_URL || ''}/auth/api/`,
		prepareHeaders: (headers) => {
			const token = localStorage.getItem('token')

			if (token) {
				headers.set('Authorization', isNewAuthSystemEnabled ? `Bearer ${token}` : '')
			}

			return headers
		},
	}),
	endpoints: (builder) => ({
		fetchAccessToken: builder.mutation({
			query: ({code, codeVerifier}) => ({
				url: 'token/',
				method: 'POST',
				body: new URLSearchParams({
					client_id: import.meta.env.VITE_APP_AUTH_CLIENT_ID || '',
					client_secret: import.meta.env.VITE_APP_AUTH_CLIENT_SECRET || '',
					code,
					code_verifier: codeVerifier,
					redirect_uri: import.meta.env.VITE_APP_CALLBACK_URI || '',
					grant_type: 'authorization_code',
				}).toString(),
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			}),
		}),
		fetchUserIdentity: builder.query({query: () => 'accounts/identity/'}),
		revokeToken: builder.mutation({
			query: (token) => ({
				url: 'revoke_token/',
				method: 'POST',
				body: new URLSearchParams({
					token,
					client_id: import.meta.env.VITE_APP_AUTH_CLIENT_ID || '',
					client_secret: import.meta.env.VITE_APP_AUTH_CLIENT_SECRET || '',
				}).toString(),
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			}),
		}),
	}),
})

export const {
	useFetchAccessTokenMutation,
	useFetchUserIdentityQuery,
	useLazyFetchUserIdentityQuery,
	useRevokeTokenMutation,
} = authApi
