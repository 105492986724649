import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {TSamplingInterface} from '../../shared/interfaces/tSampling'

type TSamplingProps = {
    value: TSamplingInterface,
    autoAveraging: string,
}

const initialState: TSamplingProps = {
	value: {
		id: 0,
		value: 'auto',
		name: '',
	},
	autoAveraging: '',
}

export const tsampling = createSlice({
	name: 'tSampling',
	initialState,
	reducers: {
		setGlobalTSampling: (state, action: PayloadAction<{ value: string, options: TSamplingInterface[] }>) => {
			let {value} = initialState
			if (!action.payload.value) {
				[value] = action.payload.options
			} else if (typeof action.payload.value === 'string') {
				const newValue = action.payload.options.find((item: TSamplingInterface) => item.value === action.payload.value)
				if (newValue) {
					value = newValue
				} else {
					[value] = action.payload.options
				}
			} else if (typeof action.payload.value === 'object') {
				value = action.payload.value
			}
			return {
				...state,
				value,
			}
		},
		setGlobalAutoTSampling: (state, action: PayloadAction<string>) => ({
			...state,
			autoAveraging: action.payload,
		}),
	},
})

// Action creators are generated for each case reducer function
export const {setGlobalTSampling, setGlobalAutoTSampling} = tsampling.actions
export default tsampling.reducer
