import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {AnomalyWithReasonInterface} from '../../shared/interfaces/anomaly'
import type {AnomalyProgramInterface} from '../../shared/interfaces/program'

const initialStateDetails: AnomalyProgramInterface = {
	id: '',
	name: '',
}

const initialState: AnomalyWithReasonInterface = {
	ad_anomaly_id: 0,
	created_at: '',
	description: '',
	end: '',
	id: '',
	reason: '',
	score: 0,
	start: '',
	step: '',
	updated_at: '',
	batch: initialStateDetails,
	machine: initialStateDetails,
	program: initialStateDetails,
	machine_sub_system: initialStateDetails,
	signal: initialStateDetails,
	end_relative: '',
	start_relative: '',
	min_batch_page_number: 1,
	min_program_page_number: 1,
	duration: '2',
	step_description: {
		description: '',
		id: '',
		signal: '',
		value: 2,
	},
}

export const anomalies = createSlice({
	name: 'anomalies',
	initialState: {current: initialState},
	reducers: {
		setCurrentAnomaly: (state, action: PayloadAction<AnomalyWithReasonInterface>) => ({
			...state,
			current: action.payload,
		}),
	},
})

// Action creators are generated for each case reducer function
export const {setCurrentAnomaly} = anomalies.actions
export default anomalies.reducer
