import {baseApi} from './base'

import type {BaseFetchResultsInterface} from '../shared/interfaces/base.ts'
import type {EquipmentTimeWindowInterface} from '../shared/interfaces/equipmentTimeWindowInterface.ts'

const searchAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		searchForEquipmentTimeWindowBasedOnSignalValue: builder.query<EquipmentTimeWindowInterface[], { equipmentSignal: string, value: string }>({
			query: ({equipmentSignal, value}) => ({
				url: 'search_for_equipment_time_window_based_on_signal_value/',
				params: {
					equipment_signal: equipmentSignal,
					value,
				},
			}),
			transformResponse: (response: BaseFetchResultsInterface<EquipmentTimeWindowInterface>) => response.results,
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
	}),
	overrideExisting: false,
})

export const {useSearchForEquipmentTimeWindowBasedOnSignalValueQuery} = searchAPI
