import {createTheme} from '@mui/material/styles'
import {sharedTheme} from './sharedTheme.ts'

declare module '@mui/material/styles' {
    // allow configuration using `createTheme`
    interface TypeBackground {
        fadedOpaque: string,
        scrollbar: string,
    }

	interface Palette {
        smallBoxShadow: string,
    }

    interface PaletteOptions {
        smallBoxShadow?: string,
    }
}

export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {main: '#cb793a'},
		secondary: {main: '#c6d8d3'},
		background: {
			default: '#030416',
			paper: '#131425',
			fadedOpaque: 'rgba(255,255,255,.05)',
			scrollbar: 'rgba(255,255,255,.2)',
		},
		smallBoxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
		mediumBoxShadow: '0px 2px 3px rgba(0, 0, 0, 0.5)',
	},
	...sharedTheme,
})
