import type {FC} from 'react'
import BasicDashboardContainer from '../../../shared/components/BasicDashboardContainer'
import ExternalBookmarkTable from './Components/ExternalBookmarkTable'

const ExternalBookmarkComponent:FC = () => (
	<BasicDashboardContainer data-testid="basic-dashboard-container-anomaly-table">
		<ExternalBookmarkTable />
	</BasicDashboardContainer>
)

export default ExternalBookmarkComponent
