export const timeRangeOptions = [
	{
		id: 0,
		value: '00:15:00',
		name: 'last_15_minutes',
	},
	{
		id: 1,
		value: '01:00:00',
		name: 'last_1_hour',
	},
	{
		id: 2,
		value: '08:00:00',
		name: 'last_8_hours',
	},
	{
		id: 3,
		value: '1 00:00:00',
		name: 'last_24_hours',
	},
	{
		id: 4,
		value: '7 00:00:00',
		name: 'last_week',
	},
	{
		id: 5,
		value: '28 00:00:00',
		name: 'last_4_weeks',
	},
	{
		id: 6,
		value: 'null',
		name: 'custom',
	},
]
