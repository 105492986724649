import cryptoJs from 'crypto-js'

export function createCodeVerifier(length = 128): string {
	const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	return Array.from(crypto.getRandomValues(new Uint8Array(length)))
		.map((x) => charset[x % charset.length])
		.join('')
}

export async function createCodeChallenge(codeVerifier: string): Promise<string> {
	// Use native crypto.subtle if available
	if (crypto.subtle) {
		const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier))
		return btoa(String.fromCharCode(...new Uint8Array(hash)))
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=+$/, '')
	}
	// Fallback to crypto-js for environments without crypto.subtle
	const hash = cryptoJs.SHA256(codeVerifier)
	return hash
		.toString(cryptoJs.enc.Base64)
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=+$/, '')
}
