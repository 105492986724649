import type {
	FC, ReactElement, CSSProperties,
} from 'react'
import {StrictMode, useCallback} from 'react'
import type {Theme} from '@mui/material/styles'
import {styled} from '@mui/material/styles'
import {
	List, Box, IconButton,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import MonitorHeartTwoToneIcon from '@mui/icons-material/MonitorHeartTwoTone'
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import {makeStyles} from '@material-ui/core/styles'
import WebAssetIcon from '@mui/icons-material/WebAsset'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import RouterIcon from '@mui/icons-material/Router'
import MenuIcon from '@mui/icons-material/Menu'
import {NavLink} from 'react-router-dom'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import NetworkPingIcon from '@mui/icons-material/NetworkPing'
import {useLogo} from '../../../shared/hooks/useLogo.ts'
import DrawerListItem from './DrawerListItem'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation'
import {useGetExternalBookmarksQuery} from '../../../apis/base.ts'
import {DEFAULT_OFFSET, DEFAULT_PAGE_SIZE} from '../../../shared/constants/preferences/pagination.ts'

const drawerWidth: number = 240

const openedMixin = (theme: Theme): CSSProperties => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
		duration: '250ms',
	}),
	overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSProperties => ({
	transition: theme.transitions.create('width', {
		easing: 'cubic-bezier(0.4, 0.0, 1, 1)',
		duration: '200ms',
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {width: `calc(${theme.spacing(8)} + 1px)`},
})

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
			duration: '250ms',
		}),
		overflowX: 'hidden',
		'& .MuiDrawer-paper': {
			backgroundColor: theme.palette.background.default,
			...openedMixin(theme),
		},
	}),
	...(!open && {
		transition: theme.transitions.create('width', {
			easing: 'cubic-bezier(0.4, 0.0, 1, 1)',
			duration: '200ms',
		}),
		overflowX: 'hidden',
		width: `calc(${theme.spacing(7)} + 1px)`,
		[theme.breakpoints.up('sm')]: {width: `calc(${theme.spacing(8)} + 1px)`},
		'& .MuiDrawer-paper': {
			backgroundColor: theme.palette.background.default,
			...closedMixin(theme),
		},
	}),
}))

const useStyles = makeStyles(() => ({rotatedIcon: {transform: 'rotate(90deg)'}}))

type Props = {
    open: boolean,
	onDrawerOpen: () => void,
}

const MBAIMainDrawer: FC<Props> = ({open, onDrawerOpen}): ReactElement => {
	const {fullLogo} = useLogo()
	const [t] = useCustomTranslation('common')
	const [t2] = useCustomTranslation('settings')
	const [t3] = useCustomTranslation('program_signal_threshold')
	const [tDashboards] = useCustomTranslation('dashboards')
	const handleDrawerOpen = useCallback(() => {
		onDrawerOpen()
	}, [onDrawerOpen])
	const {data: externalBookmarks} = useGetExternalBookmarksQuery({
		limit: DEFAULT_PAGE_SIZE,
		offset: DEFAULT_OFFSET,
	})

	const classes = useStyles()

	const listItemsTop = [
		{
			title: t('sidebar.lines_overview'),
			icon: <MonitorHeartTwoToneIcon />,
			link: '/lines_overview?view=grid',
			id: 'mbai-main-line-overview-link',
		},
		{
			title: t('sidebar.batch_traceability'),
			icon: <AltRouteIcon className={classes.rotatedIcon} />,
			link: '/batch_traceability',
			id: 'mbai-main-batch-traceability-link',
		},
		{
			title: tDashboards('dashboards'),
			icon: <WebAssetIcon />,
			link: '/dashboards',
			id: 'mbai-main-dashboard-link',

		},
		{
			title: t('signals.plural'),
			icon: <TrendingUpIcon />,
			link: '/all-signals',
			id: 'mbai-all-signals-link',
		},
		{
			title: t('anomalies'),
			icon: <TroubleshootIcon />,
			link: '/anomalies',
			id: 'mbai-main-anomalies-link',
		},
		{
			title: t('comments'),
			icon: <TextSnippetIcon />,
			link: '/comments',
			id: 'mbai-main-comments-link',
		},
		{
			title: t3('thresholds'),
			icon: <NetworkPingIcon />,
			link: '/threshold-violations',
			id: 'mbai-main-thresholds-link',

		},
	]

	const listItemsBottom = [
		{
			title: t('connections'),
			icon: <RouterIcon />,
			link: '/connections',
			id: 'mbai-main-connections-link',
		},

		{
			title: t2('title'),
			icon: <SettingsTwoToneIcon />,
			link: '/settings',
			id: 'mbai-main-settings-link',
		},
	]

	if (externalBookmarks?.results?.length) {
		listItemsBottom.unshift(
			{
				title: t('sidebar.external_bookmarks'),
				icon: <BookmarksIcon />,
				link: '/external_bookmarks',
				id: 'mbai-main-external-bookmarks-link',
			},
		)
	}

	return (
		<StrictMode>
			<Drawer
				variant="permanent"
				open={open}
				sx={
					{
						'& .MuiPaper-root': {
							backgroundColor: 'background.paper',
							borderRight: 1,
							borderColor: 'divider',
						},
						'& .MuiSvgIcon-root': {fontSize: '1.1rem'},
						display: {
							xs: 'none',
							sm: 'block',
						},
					}
				}
				data-testid="mbai-layout-drawer-component"
				id="mbai-main-layout-drawer-component"
			>
				<Box
					sx={
						{
							pl: 1.4,
							py: 1.5,
							display: 'flex',
							alignItems: 'center',
						}
					}
				>
					<IconButton
						size="large"
						onClick={handleDrawerOpen}
					>
						<MenuIcon />
					</IconButton>
					<NavLink to="/">
						<img
							src={fullLogo}
							alt="MontBlancAI MainLogo"
							style={
								{
									display: open ? 'block' : 'none',
									maxWidth: '125px',
								}
							}
						/>
					</NavLink>
				</Box>
				<List>
					{
						listItemsTop.map((text, index) => (
							<DrawerListItem
								key={text.id}
								text={text}
								index={index}
								open={open}
								idProp={text.id}
							/>
						))
					}
				</List>
				<List
					sx={
						{marginTop: 'auto'}
					}
				>
					{
						listItemsBottom.map((text, index) => (
							<DrawerListItem
								key={text.id}
								text={text}
								index={index}
								open={open}
								idProp={text.id}
							/>
						))
					}
					{/* <HubspotChat drawerOpen={open} /> */}
				</List>
			</Drawer>
		</StrictMode>
	)
}

export default MBAIMainDrawer
