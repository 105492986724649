import type {FC, ReactElement} from 'react'
import {StrictMode} from 'react'
import {
	Card, CardActions, CardContent, CardHeader, Skeleton, Box, Grid,
} from '@mui/material'

const SingleCard: FC = (): ReactElement => (
	<StrictMode>
		<Card
			data-testid="mbai-card-view-loader"
		>
			<CardHeader
				action={
					(
						<Skeleton
							variant="rounded"
							sx={
								{width: '10px'}
							}
						/>
					)
				}
				title={
					(
						<Skeleton
							variant="rounded"
							sx={
								{width: '150px'}
							}
						/>
					)
				}
			/>
			<Skeleton
				variant="rectangular"
				height="200px"
			/>
			<CardContent>
				<Skeleton
					variant="rounded"
					width="200px"
					height="14px"
					sx={
						{mb: 1}
					}
				/>
				<Skeleton
					variant="rounded"
					width="175px"
					height="10px"
					sx={
						{mb: 1}
					}
				/>
				<Skeleton
					variant="rounded"
					width="175px"
					height="10px"
				/>
			</CardContent>
			<CardActions
				sx={
					{
						backgroundColor: 'background.fadedOpaque',
						p: '16px 8px',
					}
				}
			>
				<Box
					sx={
						{
							display: 'flex',
							justifyContent: 'space-around',
							flexGrow: 1,
						}
					}
				>
					<Skeleton
						variant="rectangular"
						width="15px"
						height="15px"
					/>
					<Skeleton
						variant="rectangular"
						width="15px"
						height="15px"
					/>
					<Skeleton
						variant="rectangular"
						width="15px"
						height="15px"
					/>
				</Box>
			</CardActions>
		</Card>
	</StrictMode>
)

const CardViewLoader: FC<{ elements?: string[] }> = ({elements}): ReactElement => {
	const arrayCounter = elements ? elements.map((_, index) => (index + 1).toString()) : Array.from({length: 6}, (_, index) => (index + 1).toString())

	return (
		<StrictMode>
			<Grid
				data-testid="mbai-machine-overview-card-view-loader-component"
				container
				spacing={2}
			>
				{
					arrayCounter.map((item) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							xl={2}
							key={item}
						>
							<SingleCard />
						</Grid>
					))
				}
			</Grid>
		</StrictMode>
	)
}

export default CardViewLoader
