import type {FC} from 'react'
import CommentsTable from './Components/CommentsTable'
import BasicDashboardContainer from '../../../shared/components/BasicDashboardContainer'

const CommentComponent:FC = () => (
	<BasicDashboardContainer data-testid="basic-dashboard-container-anomaly-table">
		<CommentsTable />
	</BasicDashboardContainer>
)

export default CommentComponent
