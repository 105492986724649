import type {FC, ReactNode} from 'react'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {MenuItem} from '@mui/material/'
import BasicSelect from '../shared/components/BasicSelect'

interface FilterSelectProps {
  options: { key: string, value: string, label: ReactNode }[], // List of dropdown options
  defaultValue: string, // Default value for the param
  paramTitle: string, // Query parameter name in the URL
  label: string, // Label for the dropdown
}

const FilterSelect: FC<FilterSelectProps> = ({
	options, defaultValue, paramTitle, label,
}) => {
	const [searchParams, setSearchParams] = useSearchParams()

	useEffect(() => {
		if (!searchParams.get(paramTitle)) {
			searchParams.set(paramTitle, defaultValue)
			setSearchParams(searchParams)
		}
	}, [paramTitle, defaultValue, searchParams, setSearchParams])

	const onChange = (value: string | string[]): void => {
		searchParams.set(paramTitle, value as string)
		setSearchParams(searchParams)
	}

	return (
		<BasicSelect
			label={label}
			value={searchParams.get(paramTitle) || ''}
			onBasicSelectChange={onChange}
			displayLabel={false}
			tooltipPlacement="top"
			data-testid="mbai-single-select-filter"
		>
			{options.map((option) => (
				<MenuItem
					key={option.key}
					value={option.value}
					data-cy="mbai-line-toggle-single-menu-item"
				>
					{option.label}
				</MenuItem>
			))}
		</BasicSelect>
	)
}

export default FilterSelect
