import type {FC, ReactElement} from 'react'
import type {CardProps} from '@mui/material'
import {Card} from '@mui/material'

const BasicCard: FC<CardProps> = (
	{
		children, id, ...rest
	},
): ReactElement => (
	<Card
		data-testid="mbai-main-basic-card"
		id={id || undefined}
		{...rest}
	>
		{children}
	</Card>
)

export default BasicCard
