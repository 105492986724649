import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

interface FilterByClickDigitalTwinState {
  value: string,
}

const initialState: FilterByClickDigitalTwinState = {value: ''}

const filterByClickDigitalTwinSlice = createSlice({
	name: 'filterByClickDigitalTwin',
	initialState,
	reducers: {
		setFilterValue: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const {setFilterValue} = filterByClickDigitalTwinSlice.actions
export default filterByClickDigitalTwinSlice.reducer
