import type {SelectChangeEvent} from '@mui/material'
import {
	Box,
	FormControl, InputLabel,
	MenuItem,
	Select,
	Typography,
	useTheme,
} from '@mui/material'
import type {GridColDef} from '@mui/x-data-grid'
import {DataGrid} from '@mui/x-data-grid'
import type {FC} from 'react'
import {useState} from 'react'
import {useGetAllCommentsQuery} from '../../../../apis/base'
import BasicCard from '../../../../shared/components/BasicCard'
import {DEFAULT_OFFSET, DEFAULT_PAGE_SIZE} from '../../../../shared/constants/preferences/pagination'
import {getFormattedDate} from '../../../../util-functions'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'

const CommentsTable: FC = () => {
	const theme = useTheme()
	const [t] = useCustomTranslation('common')
	const [paginationModel, setPaginationModel] = useState({
		pageSize: DEFAULT_PAGE_SIZE,
		page: DEFAULT_OFFSET,
	})
	const [filters, setFilters] = useState({
		production_area: '',
		comment_category: '',
		line: '',
		equipment: '',
	})

	const {data: comments, isFetching: commentsFetching} = useGetAllCommentsQuery({filters})

	const handleFilterChange = (field: string) => (event: SelectChangeEvent<string>) => {
		setFilters((prev) => ({...prev, [field]: event.target.value}))
	}

	const rows = comments?.results?.map((comment) => ({
		id: comment.id,
		created_at: comment.created_at,
		production_area: comment.production_area?.name || 'N/A',
		category: comment.comment_category?.name || 'N/A',
		line: comment.machine?.name || 'N/A',
		equipment: comment.machine_sub_system?.name || 'N/A',
		text: comment.text,
	})) || []

	const columns: GridColDef[] = [
		{
			field: 'production_area',
			headerName: t('commentTable.production_area'),
			editable: false,
			flex: 1,
		},
		{
			field: 'category',
			headerName: t('commentTable.category'),
			editable: false,
			flex: 1,
		},
		{
			field: 'line',
			headerName: t('commentTable.line'),
			editable: false,
			flex: 1,
		},
		{
			field: 'equipment',
			headerName: t('commentTable.equipment'),
			editable: false,
			flex: 1,
		},
		{
			field: 'text',
			headerName: t('commentTable.text'),
			editable: false,
			flex: 2,
		},
		{
			field: 'created_at',
			headerName: t('commentTable.created_at'),
			editable: false,
			flex: 1,
			renderCell: ({value}) => (value ? getFormattedDate(value, null, {
				showDate: true,
				showYear: true,
				showTime: true,
			}) : 'N/A'),
		},
	]

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					mb: 2,
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
				data-testid="comments-table-box"
			>
				<Typography
					variant="h2"
					component="h3"
					sx={{whiteSpace: 'nowrap', alignSelf: 'center'}}
				>
					{t('comments')}
				</Typography>
				<Box sx={{display: 'flex', gap: 2}}>
					<FormControl size="small">
						<InputLabel id="filter-production-area">{t('commentTable.production_area')}</InputLabel>
						<Select
							labelId="filter-production-area"
							value={filters.production_area}
							onChange={handleFilterChange('production_area')}
							sx={{
								minWidth: '10rem',
								maxWidth: '20rem',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							<MenuItem value="">{t('all')}</MenuItem>
							{Array.from(
								new Map(
									comments?.results
										?.filter((comment) => comment.production_area !== null)
										.map((comment) => [comment.production_area!.id, comment.production_area!]),
								).values(),
							).map((area) => (
								area && (
									<MenuItem
										key={area.id}
										value={area.id}
									>
										{area.name || 'N/A'}
									</MenuItem>
								)
							))}
						</Select>
					</FormControl>
					<FormControl size="small">
						<InputLabel id="filter-comment-category">{t('commentTable.category')}</InputLabel>
						<Select
							labelId="filter-comment-category"
							value={filters.comment_category}
							onChange={handleFilterChange('comment_category')}
							sx={{
								minWidth: '10rem',
								maxWidth: '20rem',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							<MenuItem value="">{t('all')}</MenuItem>
							{Array.from(
								new Map(
									comments?.results
										?.filter((comment) => comment.comment_category !== null)
										.map((comment) => [comment.comment_category!.id, comment.comment_category!]),
								).values(),
							).map((category) => (
								category && (
									<MenuItem
										key={category.id}
										value={category.id}
									>
										{category.name || 'N/A'}
									</MenuItem>
								)
							))}
						</Select>
					</FormControl>
					<FormControl size="small">
						<InputLabel id="filter-line">{t('commentTable.line')}</InputLabel>
						<Select
							labelId="filter-line"
							value={filters.line}
							onChange={handleFilterChange('line')}
							sx={{
								minWidth: '10rem',
								maxWidth: '20rem',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							<MenuItem value="">{t('all')}</MenuItem>
							{Array.from(
								new Map(
									comments?.results
										?.filter((comment) => comment.machine !== null)
										.map((comment) => [comment.machine!.id, comment.machine!]),
								).values(),
							).map((line) => (
								line && (
									<MenuItem
										key={line.id}
										value={line.id}
									>
										{line.name || 'N/A'}
									</MenuItem>
								)
							))}
						</Select>
					</FormControl>
					<FormControl size="small">
						<InputLabel id="filter-equipment">{t('commentTable.equipment')}</InputLabel>
						<Select
							labelId="filter-equipment"
							value={filters.equipment}
							onChange={handleFilterChange('equipment')}
							sx={{
								minWidth: '10rem',
								maxWidth: '20rem',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							<MenuItem value="">{t('all')}</MenuItem>
							{Array.from(
								new Map(
									comments?.results
										?.filter((comment) => comment.machine_sub_system !== null)
										.map((comment) => [
            comment.machine_sub_system!.id,
            comment.machine_sub_system!,
										]),
								).values(),
							).map((equipment) => (
								equipment && (
									<MenuItem
										key={equipment.id}
										value={equipment.id}
									>
										{equipment.name || 'N/A'}
									</MenuItem>
								)
							))}
						</Select>
					</FormControl>

				</Box>
			</Box>
			<BasicCard>
				<DataGrid
					rows={rows}
					rowCount={comments?.count || 0}
					columns={columns}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					paginationMode="server"
					rowHeight={50}
					disableRowSelectionOnClick
					pageSizeOptions={[10, 50, 100]}
					loading={commentsFetching}
					sx={{
						height: 'calc(100vh - 165px)',
						border: 'none',
						'--DataGrid-containerBackground': theme.palette.background.paper,
					}}
				/>
			</BasicCard>
		</>
	)
}

export default CommentsTable
