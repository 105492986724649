import type {FC} from 'react'
import {
	Typography, Tooltip, Box,
} from '@mui/material'
import {useCustomTranslation} from '../../shared/hooks/useCustomTranslation'

interface LineStatusChipProps {
	connectivityStatus: string | null,
	displayLabel?: boolean,
	bold?: boolean,
	signal?: boolean,
	tooltipText?: string,
}

const LineStatusChip: FC<LineStatusChipProps> = (
	{
		connectivityStatus, bold, signal, tooltipText, displayLabel = true,
	},
) => {
	const [t] = useCustomTranslation('lines_overview')

	const getChipLabel = (): string => {
		switch (connectivityStatus) {
			case 'connected':
			case 'Connected':
				return 'Connected'
			case 'online':
				return 'Online'
			case 'disconnected':
			case 'Disconnected':
				return 'Disconnected'
			case 'offline':
				return 'Offline'
			case 'no_data_15min':
				return t('no_data_15min')
			default:
				return 'Something went wrong'
		}
	}

	const getChipColor = (): string => {
		switch (connectivityStatus) {
			case 'connected':
			case 'Connected':
				return 'success.main'
			case 'online':
				return 'success.main'
			case 'disconnected':
			case 'Disconnected':
				return 'error.main'
			case 'offline':
				return 'error.main'
			case 'no_data_15min':
				return 'primary.main'
			default:
				return 'error.main'
		}
	}

	const getTooltipTitle = (): string => {
		if (tooltipText) {
			return tooltipText
		}

		switch (connectivityStatus) {
			case 'online':
				return t('online_tooltip')
			case 'Connected':
				return signal ? t('online_tooltip_signal') : t('online_tooltip')
			case 'offline':
				return t('offline_tooltip')
			case 'Disconnected':
				return signal ? t('offline_tooltip_signal') : t('offline_tooltip')
			case 'no_data_15min':
				return t('no_data_15min_tooltip')
			default:
				return 'It is on us. Contact Support'
		}
	}

	return (
		connectivityStatus ? (
			<Tooltip
				arrow
				title={getTooltipTitle()}
			>
				<Box
					sx={
						{
							display: 'flex',
							alignItems: 'center',
							mr: 1,
						}
					}
				>
					<Box
						sx={
							{
								height: displayLabel ? '10px' : '15px',
								width: displayLabel ? '10px' : '15px',
								backgroundColor: getChipColor(),
								mr: 1,
								borderRadius: '25px',
							}
						}
					/>
					<Typography
						variant={bold ? 'body2' : 'caption'}
						sx={
							{color: bold ? 'text.primary' : 'text.disabled', fontWeight: bold ? 600 : 400}
						}
					>
						{displayLabel ? getChipLabel() : null}
					</Typography>
				</Box>
			</Tooltip>
		) : null
	)
}

export default LineStatusChip
