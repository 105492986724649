import type {FC} from 'react'
import NetworkWifi1BarIcon from '@mui/icons-material/NetworkWifi1Bar'
import NetworkWifi2BarIcon from '@mui/icons-material/NetworkWifi2Bar'
import NetworkWifi3BarIcon from '@mui/icons-material/NetworkWifi3Bar'
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar'
import SignalCellularNodataIcon from '@mui/icons-material/SignalCellularNodata'
import SignalCellularConnectedNoInternet4BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet4Bar'
import SignalCellularOffIcon from '@mui/icons-material/SignalCellularOff'
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar'
import LanIcon from '@mui/icons-material/Lan'
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff'
import PermScanWifiIcon from '@mui/icons-material/PermScanWifi'
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad'
import type {SvgIconPropsColorOverrides} from '@mui/material/SvgIcon'
import type {OverridableStringUnion} from '@mui/types'

type Props = {
    link_quality?: number,
    status?: string,
    type: string,
};

const ConnectivityIcons: FC<Props> = ({
	link_quality, type, status,
}) => {
	const wifiIconColor = (value?: string): OverridableStringUnion<'primary' | 'error' | 'success' | 'disabled' | 'action' | 'inherit' | 'secondary' | 'info' | 'warning', SvgIconPropsColorOverrides> | undefined => {
		if (value === 'updating') return 'primary'
		if (value === 'error') return 'error'
		if (value === 'connected') return 'success'
		if (value === 'disconnected') return 'disabled'
		if (!value) return 'primary'
		return 'primary'
	}

	switch (type) {
		case 'wifi':
			if (status === 'disconnected') return <SignalWifiOffIcon color={wifiIconColor(status)} />
			if (status === 'updating') return <PermScanWifiIcon color={wifiIconColor(status)} />
			if (status === 'error') return <SignalWifiBadIcon color={wifiIconColor(status)} />
			if (link_quality && link_quality >= 75) return <SignalWifi4BarIcon color={wifiIconColor(status)} />
			if (link_quality && link_quality >= 50) return <NetworkWifi3BarIcon color={wifiIconColor(status)} />
			if (link_quality && link_quality >= 25) return <NetworkWifi2BarIcon color={wifiIconColor(status)} />
			if (link_quality && link_quality > 0) return <NetworkWifi1BarIcon color={wifiIconColor(status)} />
			break
		case 'cellular':
			if (status === 'updating') return <SignalCellularConnectedNoInternet4BarIcon color="primary" />
			if (status === 'error') return <SignalCellularNodataIcon color="error" />
			if (status === 'connected') return <SignalCellular4BarIcon color="success" />
			if (status === 'disconnected') return <SignalCellularOffIcon color="disabled" />
			break
		case 'ethernet':
			if (status === 'updating') return <LanIcon color="primary" />
			if (status === 'error') return <LanIcon color="error" />
			if (status === 'connected') return <LanIcon color="success" />
			if (status === 'disconnected') return <LanIcon color="disabled" />
			break
		default:
			return null
	}
	return null
}

export default ConnectivityIcons
