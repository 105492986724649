import {useSelector} from 'react-redux'
import type {RootState} from '../../containers/App/store'
import darkMainLogo from '../../assets/mbai-official-favicon-white-2023.png'
import darkFullLogo from '../../assets/mbai-official-logo-favicon-white-2023.png'
import lightMainLogo from '../../assets/mbai-official-favicon-purple-2023.png'
import lightFullLogo from '../../assets/mbai-official-logo-favicon-purple-2023.png'

export const useLogo = (): {mainLogo: string, fullLogo: string} => {
	const theme = useSelector((state: RootState) => state.user.preferredTheme)
	const mainLogo = theme === 'dark' ? darkMainLogo : lightMainLogo
	const fullLogo = theme === 'dark' ? darkFullLogo : lightFullLogo
	return {mainLogo, fullLogo}
}
