import {type FC, useCallback} from 'react'
import {useEffect, useState} from 'react'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import {
	Grid, IconButton, Tooltip, Typography,
} from '@mui/material/'
import {Box} from '@mui/system'
import BasicModal from '../../../../shared/components/BasicModal'
import type {AnomalyWithReasonInterface} from '../../../../shared/interfaces/anomaly'
import AnomalyChat from './AnomalyChat'
import AnomalyFeedbackForm from './AnomalyFeedbackForm'
import {useUpdateAnomalyLimitedFieldsMutation} from '../../../../apis/anomalies'
import AnomalyFeedbackchatModalTitle from './AnomalyFeedbackChatModalTitle'
import {ICON_BUTTON_SIZES} from '../../../../shared/constants/IconButtonSizes.ts'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation.ts'
import AnomalyDiscussionDetails from './AnomalyDiscussionDetails.tsx'
import {useResizeObserver} from '../../../../shared/hooks/useResizeObserver.ts'

type Props = {
    row: AnomalyWithReasonInterface,
	page?: string,
}

enum AnomalyStatus {
	DETECTED = 'DETECTED',
	CLASSIFIED = 'CLASSIFIED',
	UNDER_REVIEW = 'UNDER_REVIEW',
}

const getAnomalyStatusColor = (status: string): string => {
	switch (status) {
		case AnomalyStatus.DETECTED:
			return 'error.main'
		case AnomalyStatus.CLASSIFIED:
			return 'success.main'
		case AnomalyStatus.UNDER_REVIEW:
			return 'primary.main'
		default:
			return 'error.main'
	}
}

const AnomalyFeedbackChatModal: FC<Props> = ({row, page = 'main-anomalies'}) => {
	// States
	const [open, setOpen] = useState(false)
	const [status, setStatus] = useState<string>(row.status)
	const [anomalyDetailsHeight, setAnomalyDetailsHeight] = useState<number>(300)

	// Hooks
	const [t] = useCustomTranslation('anomalies')

	// RTK Queries
	const [updateAnomalyLimitedFields] = useUpdateAnomalyLimitedFieldsMutation()

	// Constants
	const MAIN_HEIGHT = '70vh'

	useEffect(() => {
		if (open && row.status === AnomalyStatus.DETECTED) {
			updateAnomalyLimitedFields({
				id: row.id,
				status: AnomalyStatus.UNDER_REVIEW,
				is_read: true,
			})
			setStatus(AnomalyStatus.UNDER_REVIEW)
		}
	}, [open, row.status, row.id, updateAnomalyLimitedFields])

	const handleClose = (): void => {
		setOpen(false)
	}

	const handleResize = useCallback((entry: ResizeObserverEntry): void => {
		const {target} = entry

		// Get the computed style of the target element
		const computedStyle = getComputedStyle(target)

		// Extract the padding values
		const paddingTop = parseFloat(computedStyle.paddingTop)
		const paddingBottom = parseFloat(computedStyle.paddingBottom)
		const borderTop = parseFloat(computedStyle.borderTopWidth)

		// Update dimensions
		setAnomalyDetailsHeight(entry.contentRect.height + paddingTop + paddingBottom + borderTop)
	}, [])

	const anomalyFeedbackFormDetailsRef = useResizeObserver(handleResize)

	return (
		<>
			<Tooltip
				title={t('anomaly_feedback_chat.open_anomaly_discussion')}
				placement={page !== 'main-anomalies' ? 'top-start' : 'left'}
			>
				<IconButton
					onClick={() => setOpen(true)}
					data-testid="anomaly-feedback-chat-modal"
				>
					<ChatOutlinedIcon
						sx={{
							color: getAnomalyStatusColor(status),
							width: page !== 'main-anomalies' ? `${ICON_BUTTON_SIZES.medium}px` : undefined,
							height: page !== 'main-anomalies' ? `${ICON_BUTTON_SIZES.medium}px` : undefined,
						}}
					/>
				</IconButton>
			</Tooltip>
			{open ? (
				<BasicModal
					onClose={handleClose}
					title={(
						<AnomalyFeedbackchatModalTitle
							status={status}
						/>
					)}
					open={open}
					maxWidth="1200px"
					wrappers={false}
				>
					<Grid
						container
						spacing={0}
						sx={{
							backgroundColor: 'background.default',
							height: MAIN_HEIGHT,
						}}
					>
						<Grid
							item
							xs={12}
							sm={6}
						>
							<Box
								sx={{
									borderRight: 1,
									borderColor: 'divider',
								}}
							>
								<Box
									sx={{
										py: 1.5,
										px: 2.5,
										borderBottom: 1,
										borderColor: 'divider',
									}}
									ref={anomalyFeedbackFormDetailsRef}
								>
									<Typography
										variant="h4"
										component="h3"
										sx={{mb: 0.5}}
									>
										{t('anomaly_feedback_chat.details')}
									</Typography>
									<Box>
										<AnomalyDiscussionDetails
											row={row}
										/>
									</Box>
								</Box>
								<AnomalyFeedbackForm
									anomalyId={row.id}
									mainHeight={`calc(${MAIN_HEIGHT} - ${anomalyDetailsHeight}px)`}
									onSuccess={() => setStatus(AnomalyStatus.CLASSIFIED)}
								/>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
						>
							<Box
								sx={{
									py: 1.5,
									px: 2.5,
									borderBottom: 1,
									borderColor: 'divider',
								}}
							>
								<Typography
									variant="h4"
									component="h3"
								>
									{t('anomaly_feedback_chat.discussion')}
								</Typography>
							</Box>
							<AnomalyChat
								anomalyId={row.id}
								mainHeight={MAIN_HEIGHT}
							/>
						</Grid>
					</Grid>
				</BasicModal>
			) : null}
		</>
	)
}

export default AnomalyFeedbackChatModal
