import type {FC, ReactElement} from 'react'
import {useEffect, useState} from 'react'
import {Box, Card} from '@mui/material/'
import {useLocation} from 'react-router'
import {useGetConnectionByIdQuery} from '../../../../apis/edgeAPI.ts'
import ConnectivityIcons from './connectivityIcons.tsx'
import {useRowData} from './rowDataContext.tsx'
import type {ConnectionEdgeApiInterface} from '../../../../shared/interfaces/edgeapi/connectionEgeApi.ts'

const ConnectivitySyncBox: FC = (): ReactElement => {
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const {data} = useGetConnectionByIdQuery({connectionId: searchParams.get('id') || ''})
	const {rowData} = useRowData()
	const [connectionData, setConnectionData] = useState<ConnectionEdgeApiInterface>()

	useEffect(() => {
		if (rowData.length > 0) {
			const connection = rowData.find((row) => row.id === searchParams.get('id'))
			setConnectionData(connection)
		}
	}, [rowData, searchParams])

	return (
		<Card sx={
			{display: 'flex', alignItems: 'center'}
		}
		>
			<Box
				sx={
					{
						gap: 1, display: 'flex', justifyContent: 'center', margin: 0, paddingLeft: 2, paddingRight: 2, width: '100%',
					}
				}
			>
				<ConnectivityIcons
					type="ethernet"
					status={connectionData ? connectionData?.it_ethernet_status : data?.it_ethernet_status}
				/>
				<ConnectivityIcons
					type="wifi"
					link_quality={connectionData ? connectionData?.wifi_link_quality : data?.wifi_link_quality}
					status={connectionData ? connectionData?.wifi_status : data?.wifi_status}
				/>
				{
					connectionData?.cellular_enabled ? (
						<ConnectivityIcons
							type="cellular"
							status={connectionData ? connectionData?.cellular_status : data?.cellular_status}
						/>
					) : null
				}
			</Box>
		</Card>
	)
}

export default ConnectivitySyncBox
