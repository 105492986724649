import type {FC} from 'react'
import {useState} from 'react'
import {
	Box, Typography, useTheme,
} from '@mui/material'
import type {GridColDef} from '@mui/x-data-grid'
import {DataGrid} from '@mui/x-data-grid'
import {tablesFullPage} from '../../../../shared/constants/lineManagement'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'
import BasicCard from '../../../../shared/components/BasicCard'
import {useGetExternalBookmarksQuery} from '../../../../apis/base'
import {DEFAULT_OFFSET, DEFAULT_PAGE_SIZE} from '../../../../shared/constants/preferences/pagination'

const ExternalBookmarkTable: FC = () => {
	const theme = useTheme()
	const [paginationModel, setPaginationModel] = useState({pageSize: DEFAULT_PAGE_SIZE, page: DEFAULT_OFFSET})
	const {data: externalBookmarks, isFetching: externalBookmarksIsFetching} = useGetExternalBookmarksQuery({
		limit: paginationModel.pageSize,
		offset: paginationModel.page * paginationModel.pageSize,
	})
	const [t] = useCustomTranslation('common')

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('external_bookmarks.name'),
			editable: false,
			flex: 1,
		},
		{
			field: 'description',
			headerName: t('external_bookmarks.description'),
			editable: false,
			flex: 2,
		},
		{
			field: 'external_url',
			headerName: t('external_bookmarks.external_url'),
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<a
					href={params.value}
					target="_blank"
					rel="noopener noreferrer"
				>
					{params.value}
				</a>
			),
		},
	]

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					mb: 2,
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
				data-testid="external-bookmark-table-box"
			>
				<Typography
					variant="h2"
					component="h3"
				>
					{t('external_bookmarks.title')}
				</Typography>
			</Box>
			<BasicCard
				id="external-bookmarks-box"
				data-testid="mbai-main-line-management-external-bookmarks-table"
				className="selectorConfigureLinePageExternalBookmarks"
				data-cy="mbai-main-line-management-external-bookmarks-table"
			>
				<DataGrid
					rows={externalBookmarks?.results || []}
					rowCount={externalBookmarks?.count || 0}
					columns={columns}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					paginationMode="server"
					rowHeight={tablesFullPage.rowHeight}
					disableRowSelectionOnClick
					pageSizeOptions={[10, 50, 100]}
					loading={externalBookmarksIsFetching}
					sx={{
						height: 'calc(100vh - 165px)',
						border: 'none',
						'--DataGrid-containerBackground': theme.palette.background.paper,
					}}
				/>
			</BasicCard>
		</>
	)
}

export default ExternalBookmarkTable
