import type {GridRowSelectionModel} from '@mui/x-data-grid'
import {baseApi} from './base'
import type {ConnectionEdgeApiInterface} from '../shared/interfaces/edgeapi/connectionEgeApi'
import type {DataSourceEdgeApiInterface} from '../shared/interfaces/edgeapi/dataSourceEdgeApi'
import type {DataPointEdgeApiInterface} from '../shared/interfaces/edgeapi/dataPointEdgeApi'
import type {BaseFetchResultsInterface} from '../shared/interfaces/base.ts'
import type {MqttConnectionEdgeApiInterface} from '../shared/interfaces/edgeapi/mqttConnectionEdgeApi.ts'
import type {PaginatedResponse} from '../shared/interfaces/common/paginatedResponse.ts'

const lineDashboardAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		createConnectionEdgeApi: builder.mutation<{ url: string, connection_uuid?: string, message?: string }, { body: Omit<ConnectionEdgeApiInterface, 'id'> }>({
			query: ({body}) => ({
				url: 'edge/connections/', // Updated URL
				method: 'POST',
				body,
			}),
		}),
		updateConnectionEdgeApi: builder.mutation<{ url: string, message?: string }, {id: string, body: Partial<ConnectionEdgeApiInterface> }>({
			query: ({id, body}) => ({
				url: `edge/connections/${id}/`, // Updated URL
				method: 'PATCH',
				body,
			}),
		}),
		updateConnectionByIdentifierEdgeApi: builder.mutation<{ url: string, message?: string }, {identifier: string, body: Partial<ConnectionEdgeApiInterface> }>({
			query: ({identifier, body}) => ({
				url: `edge/connections/search_by_identifier/?identifier=${identifier}`, // Updated URL
				method: 'PATCH',
				body,
			}),
		}),
		deleteConnectionEdgeApi: builder.mutation<{ message: string }, { connection_id: string }>({
			query: ({connection_id}) => ({
				url: `edge/connections/${connection_id}/`, // Updated URL
				method: 'DELETE',
			}),
		}),
		createDataSourceEdgeApi: builder.mutation<{ url: string, data_source_uuid?: string, message?: string }, { body: Omit<DataSourceEdgeApiInterface, 'id'> }>({
			query: ({body}) => ({
				url: 'edge/data-sources/',
				method: 'POST',
				body,
			}),
		}),
		updateDataSourceEdgeApi: builder.mutation<{ url: string, message?: string }, { data_source_id: string, body: Partial<DataSourceEdgeApiInterface> }>({
			query: ({data_source_id, body}) => ({
				url: `edge/data-sources/${data_source_id}/`,
				method: 'PATCH',
				body,
			}),
		}),
		deleteDataSourceEdgeApi: builder.mutation<{ message: string }, { data_source_id: string }>({
			query: ({data_source_id}) => ({
				url: `edge/data-sources/${data_source_id}/`,
				method: 'DELETE',
			}),
		}),
		createDataPointEdgeApi: builder.mutation<{ url: string, data_point_uuid?: string, message?: string }, { body: Omit<DataPointEdgeApiInterface, 'id'> }>({
			query: ({body}) => ({
				url: 'edge/datapoints/', // Updated endpoint
				method: 'POST',
				body,
			}),
		}),
		createConnectionMQTT: builder.mutation<MqttConnectionEdgeApiInterface, {name: string, description: string, tenantId: string}>({
			query: ({
				name, description, tenantId,
			}) => ({
				url: 'edge/connections-mqtt/',
				method: 'POST',
				body: {
					name, description, tenant_id: tenantId,
				},
			}),
		}),
		updateDataPointEdgeApi: builder.mutation<{ url: string, message?: string }, { data_point_id: string, body: Partial<DataPointEdgeApiInterface> }>({
			query: ({data_point_id, body}) => ({
				url: `edge/datapoints/${data_point_id}/`, // Updated endpoint
				method: 'PATCH',
				body,
			}),
		}),
		deleteDataPointEdgeApi: builder.mutation<{ message: string }, { data_point_id: string }>({
			query: ({data_point_id}) => ({
				url: `edge/datapoints/${data_point_id}/`, // Updated endpoint
				method: 'DELETE',
			}),
		}),
		deleteDataPointBulkEdgeApi: builder.mutation<{ message: string }, { ids: GridRowSelectionModel }>({
			query: ({ids}) => ({
				url: 'edge/datapoints/bulk_delete/', // Updated endpoint
				method: 'DELETE',
				body: {ids},
			}),
		}),
		getConnectionsByTenant: builder.query<ConnectionEdgeApiInterface[], void>({
			query: () => 'edge/connections/',
			transformResponse: (response: BaseFetchResultsInterface<ConnectionEdgeApiInterface>) => response.results,
		}),
		getMqttConnectionsByTenant: builder.query<MqttConnectionEdgeApiInterface[], void>({
			query: () => 'edge/connections-mqtt/',
			transformResponse: (response: BaseFetchResultsInterface<MqttConnectionEdgeApiInterface>) => response.results,
		}),
		getConnectionsByIdentifier: builder.query<ConnectionEdgeApiInterface, { identifier: string }>({
			query: ({identifier}) => `edge/connections/search_by_identifier/?identifier=${identifier}`,
			transformResponse: (response: ConnectionEdgeApiInterface | BaseFetchResultsInterface<ConnectionEdgeApiInterface>) => response as ConnectionEdgeApiInterface,
		}),
		// get Connection by Id
		getConnectionById: builder.query<ConnectionEdgeApiInterface, { connectionId: string }>({query: ({connectionId}) => `edge/connections/${connectionId}/`}),
		getNameByConnectionId: builder.query<{name: string}, { connectionId: string }>({query: ({connectionId}) => `edge/connections/get_name/?connection_id=${connectionId}`}),
		getDataSourcesByConnection: builder.query<PaginatedResponse<DataSourceEdgeApiInterface>, { connectionId: string, limit?: number, offset?: number }>({
			query: ({
				connectionId, limit = 10, offset = 0,
			}) => `edge/data-sources/?connection=${connectionId}&limit=${limit}&offset=${offset}`,
			transformResponse: (response: PaginatedResponse<DataSourceEdgeApiInterface>) => response,
		}),
		getNameByDataSourceId: builder.query<{name: string}, { dataSourceId: string }>({query: ({dataSourceId}) => `edge/data-sources/get_name/?data_source_id=${dataSourceId}`}),
		getDataPointsByDataSource: builder.query<DataPointEdgeApiInterface[], { dataSourceId: string }>({
			query: ({dataSourceId}) => `edge/datapoints/?data_source_id=${dataSourceId}&limit=5000`,
			transformResponse: (response: BaseFetchResultsInterface<DataPointEdgeApiInterface>) => response.results,
		}),
	}),
	overrideExisting: false,
})

export const {
	useUpdateConnectionEdgeApiMutation,
	useCreateDataSourceEdgeApiMutation,
	useUpdateDataSourceEdgeApiMutation,
	useDeleteDataSourceEdgeApiMutation,
	useCreateDataPointEdgeApiMutation,
	useUpdateDataPointEdgeApiMutation,
	useDeleteDataPointEdgeApiMutation,
	useDeleteDataPointBulkEdgeApiMutation,
	useUpdateConnectionByIdentifierEdgeApiMutation,
	useGetConnectionsByTenantQuery,
	useGetConnectionByIdQuery,
	useGetDataSourcesByConnectionQuery,
	useGetDataPointsByDataSourceQuery,
	useGetNameByConnectionIdQuery,
	useGetNameByDataSourceIdQuery,
	useLazyGetConnectionsByIdentifierQuery,
	useCreateConnectionEdgeApiMutation,
	useCreateConnectionMQTTMutation,
	useGetMqttConnectionsByTenantQuery,
} = lineDashboardAPI
