import {Box, Typography} from '@mui/material/'
import type {FC} from 'react'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation.ts'
import AnomalyDetailsText from './AnomalyDetailsText.tsx'

type Props = {
    status: string,
};

const AnomalyFeedbackChatModalTitle: FC<Props> = ({status}) => {
	const [t] = useCustomTranslation('anomalies')

	return (
		<Box
			sx={{display: 'flex', alignItems: 'center'}}
			data-testid="anomaly-feedback-chat-modal-title"
		>
			<Box sx={{display: 'flex'}}>
				<Typography
					variant="h4"
					component="h2"
					sx={{mr: 1}}
				>
					{t('anomaly_feedback_chat.anomaly_feedback_and_discussion')}
				</Typography>
				{
					status ? (
						<AnomalyDetailsText
							detail={t('anomaly_feedback_chat.status')}
							value={`${status ? t(`anomaly_feedback_chat.${status}`) : ''}`}
						/>
					) : null
				}
			</Box>
		</Box>
	)
}

export default AnomalyFeedbackChatModalTitle
