export const tables = {
	pageSize: 8,
	rowHeight: 50,
}

export const edgeApiTables = {
	pageSizes: [10, 25, 50],
	rowHeight: 50,
}

export const tablesFullPage = {
	pageSize: 100,
	rowHeight: 50,
}

export const tablesSmall = {
	pageSize: 5,
	rowHeight: 50,
}

export const tableMedium = {
	pageSize: 25,
	rowHeight: 50,
}
